import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from "./pages/login";
import MainLayout from "./pages/main-layout";
import PlanTool from "./pages/sub-router/plan-tool";
import Projects from "./pages/sub-router/projects";
import BasicInfo from "./pages/sub-router/basic-info";
import {FloatButton, Popover} from "antd";
import Icon from '@ant-design/icons';
import {EvaluateIcon} from "./assets/svg/evaluate";
import Evaluate from "./components/evaluate";
import React, {useEffect} from "react";
import generateFingerprintUUID from "./utils/fingerprint";

import './App.css';
import LoginModal from "./components/login-modal";
import {observer} from "mobx-react";
import {planStore} from "./store";
import LoadingDemo from "./components/TxtLoading/LoadingDemo";
import LoadingModal from "./components/loading-modal";
import {logOperate} from "./services/api";

function App() {

    useEffect(() => {
        // 使用此函数获取UUID
        generateFingerprintUUID().then(uuid => {
            console.log('uuid', uuid);
            planStore.setFingerprintId(uuid);
            logOperate({
                traceId: uuid,
                type: 'pv',
            }).then(res => {
                console.log('logOperate', res);
            }).catch(err => {
                console.error(err);
            })
        });
    }, []);

    return (
        <>
            <Router>
                <Routes>
                    <Route path="login" element={<Login/>}/>
                    <Route path="/" element={<MainLayout/>}>
                        <Route index element={<PlanTool/>}/>
                        <Route path={"projects"} element={<Projects/>}/>
                        <Route path={"basicInfo"} element={<BasicInfo/>}/>
                    </Route>
                </Routes>
            </Router>
            <Popover placement="top" title='' content={Evaluate} 
                  trigger="click"
                  defaultOpen={planStore.currentStep === 4}
                  open={planStore.isShowEvaluate}
                  onOpenChange={(open) => {
                    planStore.setIsShowEvaluate(open)
                  }}
                  >
                <FloatButton
                    shape="square"
                    style={{right: '4%', bottom: '50%'}}
                    description="评价"
                    icon={<Icon component={EvaluateIcon}/>}
                />
            </Popover>
            <LoginModal isVisible={planStore.loginModalVisible}
                        onClose={() => planStore.setLoginModalVisible(false)}/>
            <LoadingModal isVisible={planStore.submitModalVisible}
                          onClose={() => planStore.setSubmitModalVisible(false)}/>
        </>

    );
}

export default observer(App);
