import TopIconGroup from "./top-icon-group";
import Account from "./account";

import './style.scss'
import {GlobalOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

function TopHead() {

    // 根据路由参数显示不同的标题
    const {pathname} = useLocation();
    const [title, setTitle] = useState('');

    useEffect(() => {
        switch (pathname) {
            case '/':
                setTitle('储能规划设计工具 v1.0.2');
                break;
            case '/projects':
                setTitle('储能项目管理');
                break;
            case '/basicInfo':
                setTitle('基本信息编辑');
                break;
            default:
                setTitle('');
        }
    }, [pathname]);


    return (
        <div className='top-head-content'>
            {/*<TopIconGroup></TopIconGroup>*/}
            <div className="top-head-content-left">
                {title}
            </div>

            <div className="top-head-content-right">
                <Account></Account>
                <Tooltip title="开发中..." placement={'left'}>
                    <GlobalOutlined color="#525A65" style={{cursor: 'pointer'}}/>
                </Tooltip>
            </div>

        </div>
    );
}

export default TopHead;
