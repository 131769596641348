import './style.scss'
import logoIcon from '../../assets/images/logoicon.png';
import MenuItem from "../menu-item";
import TimeDisplay from "../time-display";
import {Divider, Popover} from "antd";
import PlainIcon from "../../assets/svg/plain";
import {useNavigate} from 'react-router-dom';
import {ManagerIcon} from "../../assets/svg/manager";
import {PersonalIcon} from "../../assets/svg/personal";
import {EvaluateIcon} from "../../assets/svg/evaluate";
import Evaluate from "../evaluate";
import {observer} from "mobx-react";
import {planStore} from "../../store";


function LeftSide() {
    const navigate = useNavigate();

    // 默认导航
    const defaultNav = [
        {
            title: '规划',
            icon: <PlainIcon/>,
            onClick: () => {
                navigate('/')
            }
        },
        {
            title: '管理',
            icon: <ManagerIcon/>,
            onClick: () => {
                if (planStore.isLogin) {
                    navigate('/projects')
                } else {
                    planStore.setLoginModalVisible(true);
                }

            }
        },
        {
            title: '我的',
            icon: <PersonalIcon/>,
            onClick: () => {
                if (planStore.isLogin) {
                    navigate('/basicInfo')
                } else {
                    planStore.setLoginModalVisible(true);
                }
            }
        },
    ]

    return (
        <div className="left-side">
            <img src={logoIcon} alt="Custom Icon" className="left-side__logo"/>

            <div className="left-side__content">
                {
                    defaultNav.map((item, index) => (
                        <div key={`menu-${index}`}>
                            <MenuItem icon={item.icon} title={item.title} onClick={item.onClick}></MenuItem>
                            <Divider style={{borderColor: '#fff', opacity: 0.25, margin: 0}}/>
                        </div>))
                }
            </div>
            <div className="left-side__footer">
                <Divider style={{borderColor: '#fff', opacity: 0.25, margin: 0}}/>
                {/*<Popover placement="right" title='' content={Evaluate}>*/}
                {/*    <MenuItem icon={<EvaluateIcon />} title={"评价"}></MenuItem>*/}
                {/*</Popover>*/}

                <TimeDisplay></TimeDisplay>
            </div>
        </div>
    );
}

export default observer(LeftSide);
