import DividerTitle from "../../divider-title";
import {InputNumber, Tooltip} from "antd";

import './style.scss'
import {observer} from "mobx-react";
import {planStore} from "../../../store";
import {InfoCircleOutlined} from "@ant-design/icons";

function FourthStep() {
    return (<div className="forth-step main-content-width">
        <DividerTitle title={'经济性参数'}></DividerTitle>
        <div className="forth-step__item">
            <span className="forth-step__item__label">系统单瓦造价<Tooltip title="此处为EPC价格，应包括项目开发、储能设备、施工、辅材等全部成本">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>：</span>
            <InputNumber defaultValue={planStore.systemTileCost}
                         onChange={(value) => planStore.setSystemTileCost(value)}/>
            <span className="label2">元/Wh</span>
        </div>
        <DividerTitle title={'性能参数'}></DividerTitle>
        <div className="forth-step__item">
            <span className="forth-step__item__label">可放电深度
                 <Tooltip title="使用过程中可释放的电能量与其总容量的比例">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                ：</span>
            <InputNumber defaultValue={planStore.dischargeableDepth}
                         onChange={(value) => planStore.setDischargeableDepth(value)}
                         step={0.01}
            />
            <span className="label2">%</span>
        </div>
        <div className="forth-step__item">
            <span className="forth-step__item__label">电池充放效率
                 <Tooltip title="储能系统直流侧充入能量与输入能量之比">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                ：</span>
            <InputNumber defaultValue={planStore.fillChargeEffic}
                         onChange={(value) => planStore.setFillChargeEffic(value)}
                         step={0.01}
            />
            <span className="label2">%</span>
        </div>
        <div className="forth-step__item">
            <span className="forth-step__item__label">PCS效率
                <Tooltip title="PCS将太阳能电池板发出的电转换成电力的效率">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                ：</span>
            <InputNumber defaultValue={planStore.pcsEffic} onChange={(value) => planStore.setPcsEffic(value)}
                         step={0.01}/>
            <span className="label2">%</span>
        </div>
        <div className="forth-step__item">
            <span className="forth-step__item__label">其他效率
                <Tooltip title="其他可能影响系统性能的效率，例如线缆或变压器引入的损耗">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                ：</span>
            <InputNumber defaultValue={planStore.otherEffic} onChange={(value) => planStore.setOtherEffic(value)}
                         step={0.01}/>
            <span className="label2">%</span>
        </div>
        <DividerTitle title={'损耗参数'}></DividerTitle>
        <div className="forth-step__item">
            <span className="forth-step__item__label">自耗电量
                <Tooltip title="包含但不限于储能系统内部的空调、风扇等大功率用电器的耗电">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>

                ：</span>
            <InputNumber defaultValue={planStore.selfPowerConsumption}
                         onChange={(value) => planStore.setSelfPowerConsumption(value)} step={0.01}/>
            <span className="label2">KWh/天</span>
        </div>
    </div>);
}

export default observer(FourthStep);
