import TitleBoard from "../../components/title-board";
import OpStep from "../../components/op-step";
import DividerTitle from "../../components/divider-title";

import './plan-tool.scss'
import BottomControl from "../../components/bottom-control";
import FirstStep from "../../components/plan-step/first-step";
import {Form} from "antd";
import {useEffect, useState} from "react";
import SecondStep from "../../components/plan-step/second-step";
import ThirdStep from "../../components/plan-step/third-step";
import FourthStep from "../../components/plan-step/fourth-step";
import ViewResults from "../../components/plan-step/view-results";
import {observer} from "mobx-react";
import {queryStationById} from "../../services/api";
import {planStore} from "../../store";

function PlanTool() {
    const [formData, setFormData] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        // 判断url是否包含stationId参数
        const url = new URL(window.location.href);
        const stationId = url.searchParams.get('stationId');
        if (stationId) {
            setTimeout(
                () => {
                    queryStationById(stationId).then(res => {
                        console.log('queryStationById', res);
                        if (res.data.code === 0) {
                            const data = res.data.data;
                            if (data) {
                                planStore.initData(data)
                            }
                        }
                    }).catch(err => {
                            console.error(err);
                        }
                    )
                }, 300
            )

        }
    }, []);

    return (
        <div className="plan-tool">
            {/*<TitleBoard subTitle={'规划设计'} title={'储能规划设计工具'}></TitleBoard>*/}
            <div className="plan-tool__main">
                <OpStep currentStep={planStore.currentStep} setCurrentStep={planStore.setCurrentStep}/>
                <div className="plan-tool__content">
                    {planStore.currentStep === 0 &&
                        <FirstStep form={form} formData={formData}></FirstStep>}
                    {planStore.currentStep === 1 &&
                        <SecondStep form={form} formData={formData}></SecondStep>}
                    {planStore.currentStep === 2 &&
                        <ThirdStep form={form} formData={formData}></ThirdStep>}
                    {planStore.currentStep === 3 &&
                        <FourthStep form={form} formData={formData}></FourthStep>}
                    {planStore.currentStep === 4 &&
                        <ViewResults form={form} formData={formData}></ViewResults>}
                </div>
                <BottomControl form={form}
                               formData={formData}
                               setFormData={setFormData}
                ></BottomControl>
            </div>
        </div>
    );
}

export default observer(PlanTool);
