// 分页查询储能站点
import {post, put, get, del} from "./index";

/**
 * @typedef {Object} Station
 * @property {number} id - 储能站点ID。
 * @property {string} name - 储能站点名称。
 * @property {string} province - 储能站点所在省份。
 * @property {string} city - 储能站点所在城市。
 * @property {number} elecPriceTemplateId - 电价模板ID。
 * @property {number} loadCurveId - 负载曲线ID。
 * @property {number} effectiveDays - 有效天数。
 * @property {number} transformerCapacity - 变压器容量。
 * @property {string} antiReflux - 反流措施。
 * @property {string} antiOverload - 过载措施。
 * @property {number} mode - 模式。
 * @property {number} confidence - 置信度。
 * @property {string} flatCharging - 平均充电。
 * @property {number} fillChargeEffic - 充电效率。
 * @property {number} pcsEffic - PCS效率。
 * @property {number} otherEffic - 其他效率。
 * @property {number} systemEffic - 系统效率。
 * @property {number} createdBy - 创建者ID。
 * @property {string} createdTime - 创建时间。
 * @property {number} updatedBy - 更新者ID。
 * @property {string} updatedTime - 更新时间。
 * @property {number} adjustingPowerConsumption - 调整功耗。
 */
/**
 * @typedef {Object} StationResult
 * @property {number} id - 结果ID。
 * @property {number} stationId - 站点ID。
 * @property {number} recommendBatteryCapacity - 推荐电池容量。
 * @property {number} recommendPcsEffic - 推荐PCS效率。
 * @property {number} paybackTime - 回收时间。
 * @property {number} averageCyclesPerday - 每天平均循环次数。
 * @property {number} createdBy - 创建者ID。
 * @property {string} createdTime - 创建时间。
 * @property {number} updatedBy - 更新者ID。
 * @property {string} updatedTime - 更新时间。
 */

/**
 * 分页查询储能站点
 * @param {Station} stations - 查询参数对象，必须包括所有必需字段。
 * @returns {Promise} Axios promise representing the HTTP response.
 */
export const fetchStations = (stations) => {
    return get('/stations', {stations});
};

// 更新储能站点数据
export const updateStation = (stations) => {
    return put('/stations', stations);
};

/**
 *  新增储能站点数据
 * @param stations {{
 *   "name": string,
 *   "province": string,
 *   "city": string,
 *   "elecPriceTemplateId": number,
 *   "loadCurveId": number,
 *   "adjustingPowerConsumption": number,
 *   "effectiveDays": number,
 *   "transformerCapacity": number,
 *   "antiReflux": number,
 *   "antiOverload": number,
 *   "mode": number,
 *   "confidence": number,
 *   "flatCharging": number,
 *   "systemTileCost": number,
 *   "fillChargeEffic": number,
 *   "pcsEffic": number,
 *   "otherEffic": number,
 *   "systemEffic": number,
 *   "selfPowerConsumption": number,
 *   "traceId": string,
 * }}
 * @return {*}
 */
export const addStation = (stations) => {
    return post('/stations', stations);
};

// 删除储能站点数据
export const deleteStation = (id) => {
    return del('/stations', {params: {id}});
};

// 通过站点id触发结算
export const settlement = (id) => {
    return post(`/stations/result/compute/${id}`);
};

// 用户自定义电池容量和PCS
export const customCompute = (id,values) => {
    return post(`/stations/result/customCompute/${id}`, values);
};

// 通过站点id绑定站点
export const bindingStation = (id) => {
    return put(`/stations/bind/${id}`);
};

// 更新输出结果数据
export const updateStationResult = (stationResult) => {
    return put('/stationResult', stationResult);
};

// 新增输出结果数据
export const addStationResult = (stationResult) => {
    return post('/stationResult', stationResult);
};

/**
 * @typedef {Object} VerifyParam
 * @property {string} phone - 手机号。
 */

/**
 * 短信验证码发送
 * @returns {Promise} Axios promise representing the HTTP response.
 * @param {VerifyParam} verityParam
 */
export const sendVerificationCode = (verityParam) => {
    return post('/verify', verityParam);
};

// 获取个人信息
export const getProfile = () => {
    return get('/profile');
};

// 更新个人信息
export const updateProfile = (userData) => {
    return post('/profile', userData);
};

// 手机验证码登录授权
export const loginWithVerificationCode = (loginVerifyParam) => {
    return post('/login', loginVerifyParam);
};

/**
 * 上报用户操作
 * @param operateLog {{traceId: string; type: 'pv'|'input'}}
 * @return {*}
 */
export const logOperate = (operateLog) => {
    return post('/log', operateLog);
};

// 导入电价数据
export const importPriceData = (dto) => {
    return post('/import/price', dto);
};

// 导入负载曲线文件
export const importLoadCurveData = (dto) => {
    return post('/import/loadCurve', dto);
};

/**
 * 导入负载曲线数据
 * @param dto {{
 *     city: string,
 *     province: string,
 *     data: string, // json字符串
 * }}
 * @return {*}
 */
export const importLoadCurveByData = (dto) => {
    return post('/loadCurve', dto);
};


// 查询评价数据
export const getEvaluationData = (traceId) => {
    return get('/evaluate', {traceId});
};

// 保存评价数据
export const saveEvaluationData = (evaluate) => {
    return post('/evaluate', evaluate);
};

// 保存电价数据
// {
//   "province": "string",
//   "city": "string",
//   "data": [
//     {
//       "time": "string",
//       "hour": 0,
//       "price": 0,
//       "type": 0
//     }
//   ]
// }
/**
 * 保存电价数据
 * @param elecPriceSaveDTO {{
 *  "province": string,
 *  "city": string,
 *  "data":{
 *  "time": string,
 *  "hour": number,
 *  "price": number,
 *  "type": number
 *  }[]
 *
 *  "months": string
 *  }}
 * @return {*}
 */
export const saveElecPriceData = (elecPriceSaveDTO) => {
    return post('/elecPrice', elecPriceSaveDTO);
};

// 通过ID查询单条储能站点数据
export const queryStationById = (id) => {
    return get(`/stations/${id}`);
};

// 通过站点ID查询单条输出结果数据
export const queryResultByStationId = (id) => {
    return get(`/stations/result/${id}`);
};

/**
 * 根据ID查询单条结果数据
 * @param {number} id - 结果ID。
 * @returns {Promise} Axios promise representing the HTTP response.
 */
export const queryStationResultById = (id) => {
    return get(`/stationResult/${id}`);
};

// 根据ID查询负载曲线数据
export const getLoadCurveById = (id) => {
    return get(`/loadCurve/${id}`);
};

// 根据城市查询电价模板数据
export const getPriceByCity = (province, city) => {
    // return get('/getElecPrice', {city, province,});
    return get('/getElecPrice', {province,});
};

// 根据id查询电价模板
export const getElecPriceById = (id) => {
    return get(`/getElecPriceById`, {id});
};
