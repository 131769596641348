// 功能函数，用于将每小时的负荷数据复制4次，对应到每个15分钟的时间段
import {TemplateTypes, UsageTypes} from "../store";

function replicateHourlyLoad(hourlyLoads) {
    return hourlyLoads.flatMap(load => [load, load, load, load]);
}

// 功能函数，计算按总用电量调整后的每小时负荷百分比
function computeLoadDistribution(loadEditDay24h, totalPowerConsumption) {
    const totalLoad = loadEditDay24h.reduce((sum, load) => sum + load, 0);
    return loadEditDay24h.map(load => (load / totalLoad) * totalPowerConsumption);
}

// 主函数，生成全年每15分钟的负荷数据
// const loadEditDay24h = [50, 50, 50, 50, 50, 50, 50, 200, 400, 50, 50, 50, 50, 50, 50, 50, 200, 400, 50, 50, 50, 50, 200, 400];
// const loadEditType = TemplateTypes.OFFICE;  // 使用的负荷模板类型
// const loadEditMonthYear = UsageTypes.MONTHLY;  // 选择的用电量类型
// const enableLoadEditWeekend = true;  // 是否考虑周末
// const loadEditWeekendPercent = 30;  // 周末用电量占比
// const loadEditYearPowerConsumption = 120000;  // 年总用电量
// const monthlyPowerConsumptions = [250, 350, 450, 550, 650, 750, 950, 8200, 7400, 950, 500, 500];  // 每月用电量列表
// 主要的函数定义，接收多个参数
export function generateYearlyLoadData({
                                           loadEditDay24h,  // 每小时的用电功率模板
                                           loadEditType,   // 选择的模板类型
                                           loadEditMonthYear,  // 用户提供的用电量类型（年度或月度）
                                           enableLoadEditWeekend,  // 是否考虑周末的设置
                                           loadEditWeekendPercent,  // 周末用电调整的百分比
                                           loadEditYearPowerConsumption,  // 年总用电量
                                           loadEditMonthsPowerConsumption // 每月的用电量
                                       }) {
    const result = [];  // 结果数组，存储所有数据点
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // 2022年每月的天数
    const startDate = new Date('2022-01-01'); // 起始日期
    let currentDate = new Date(startDate); // 当前处理日期

    // 辅助函数：根据月份获取当天的平均用电量
    function getDailyPowerConsumption(month) {
        // 如果使用的是自定义，则不需要填写年月用电量，按照当天的用电量计算
        if (loadEditType === TemplateTypes.CUSTOM) {
            return loadEditDay24h.reduce((sum, load) => sum + load, 0);
        }
        if (loadEditMonthYear === UsageTypes.ANNUAL) {
            return loadEditYearPowerConsumption / 365; // 将年用电量平均分配到每天
        } else if (loadEditMonthYear === UsageTypes.MONTHLY) {
            return loadEditMonthsPowerConsumption[month] / daysInMonth[month]; // 将月用电量平均分配到该月的每一天
        }
    }

    // 遍历每个月和每天
    for (let month = 0; month < 12; month++) {
        for (let day = 0; day < daysInMonth[month]; day++) {
            currentDate.setHours(0, 0, 0, 0);  // 设置到当天的午夜

            const dailyPowerConsumption = getDailyPowerConsumption(month);
            const hourlyPowerConsumption = computeLoadDistribution(loadEditDay24h, dailyPowerConsumption);

            for (let hour = 0; hour < 24; hour++) {
                for (let quarter = 0; quarter < 4; quarter++) { // 每小时四个15分钟区间
                    let finalPower = hourlyPowerConsumption[hour];
                    if (enableLoadEditWeekend && (currentDate.getDay() === 0 || currentDate.getDay() === 6)) {
                        finalPower *= loadEditWeekendPercent / 100;
                    }
                    result.push({ time: currentDate.getTime(), loads: finalPower });

                    // 检查是否即将跨日
                    if (hour == 23 && quarter == 3) {
                        // 如果是晚上23:45，不增加15分钟，因为下面会增加一天并重置时间到午夜
                    } else {
                        currentDate.setMinutes(currentDate.getMinutes() + 15);
                    }
                }
            }

            // 一天的数据处理完成后，增加一天，时间已经在23:45，所以这里重置到下一天的午夜
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate.setHours(0, 0, 0, 0);  // 再次确保设置到午夜
        }
    }

    return result; // 返回整年的用电数据数组
}
