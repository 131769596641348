import TitleBoard from "../../components/title-board";
import './projects.scss';
import ProjectItem from "../../components/project-item";
import {useEffect} from "react";
import {fetchStations} from "../../services/api";
import {planStore} from "../../store";
import {Empty} from "antd";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";

function Projects() {

    const navigate = useNavigate();

    const fetchProjects = () => {
        fetchStations().then(res => {
            console.log('fetchStations', res);
            const records = res.data?.data?.records;
            if (records) {
                planStore.setProjectList(records);
            }
        }).catch(
            err => {
                console.log('fetchStations', err);
            }
        )
    }

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div className='plan-project-container'>
            {/*<TitleBoard subTitle={'项目管理'} title={'储能项目'}></TitleBoard>*/}
            <div className="plan-project__main">
                {
                    planStore.projectList.length === 0 &&
                    <div className="plan-project__main_empty"><Empty description={'暂无数据，快去规划板块创建吧！'}/>
                    </div>
                }
                {
                    planStore.projectList.map((item, index) => {
                        return <ProjectItem key={index} project={item} fetchProjects={fetchProjects}></ProjectItem>
                    })
                }
            </div>
        </div>
    );
}

export default observer(Projects);
