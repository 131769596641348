import {makeAutoObservable} from "mobx";
import {getElecPriceById} from "../services/api";
import {isArray, isNumber} from "lodash";
import {message} from "antd";

// Enum for template types
export const TemplateTypes = {
    OFFICE: 1,    // 办公楼
    FACTORY: 2,  // 工厂
    MALL: 3,        // 商场
    CUSTOM: 4,     // 自定义
};

// Enum for usage types
export const UsageTypes = {
    ANNUAL: 1,  // 年
    MONTHLY: 2, // 月
};

// 规划工具全局状态
class PlanStore {

    // 是否开启符合自行编辑， todo 修改初始数据
    enableLoadEdit = false;

    // 负荷模板类型
    loadEditType = TemplateTypes.OFFICE;

    // 负荷自定义24个日用电量
    loadEditDay24h = [
        50,
        50,
        50,
        50,
        50,
        50,
        50,
        200,
        300,
        400,
        400,
        400,
        300,
        300,
        400,
        400,
        400,
        400,
        300,
        200,
        100,
        50,
        50,
        50,
    ];

    // 负荷选择年还是月用电量
    loadEditMonthYear = UsageTypes.ANNUAL;

    // 负荷年用电量
    loadEditYearPowerConsumption = 0;

    // 负荷12个月用电量
    loadEditMonthsPowerConsumption = Array(12).fill(0);

    // 是否选择考虑周末
    enableLoadEditWeekend = false;

    // 周末占当前月平时用电量的占比
    loadEditWeekendPercent = 0;

    // 负荷编辑或者上传
    setEnableLoadEdit(enableLoadEdit) {
        this.enableLoadEdit = enableLoadEdit;
    }

    // Actions to manage the state
    setLoadEditType(type) {
        this.loadEditType = type;
    }

    // 判断负荷类型是否为自定义
    isLoadEditTypeCustom() {
        return this.loadEditType === TemplateTypes.CUSTOM;
    }

    setLoadEditDay24h(day24h) {
        this.loadEditDay24h = day24h;
    }

    updateLoadEditDay24h(hour, usage) {
        const loadEditDay24h = this.loadEditDay24h.slice();
        loadEditDay24h[hour] = usage;
        this.loadEditDay24h = loadEditDay24h;
    }

    setLoadEditMonthYear(type) {
        this.loadEditMonthYear = type;
    }

    setLoadEditYearPowerConsumption(usage) {
        this.loadEditYearPowerConsumption = usage;
    }

    updateLoadEditMonthsPowerConsumption(month, usage) {
        let months = this.loadEditMonthsPowerConsumption.slice();
        months[month] = usage;
        this.loadEditMonthsPowerConsumption = months;
    }

    toggleEnableLoadEditWeekend() {
        this.enableLoadEditWeekend = !this.enableLoadEditWeekend;
    }

    // 接收百分数（10， 20 等）
    setLoadEditWeekendPercent(percent) {
        this.loadEditWeekendPercent = percent;
    }

    // 判断负荷仿真器数据是否符合计算要求
    isLoadEditValid() {
        if (!this.loadEditDay24h.every(isNumber)) {
            message.error('请填写完整24小时用电量');
            return false;
        }

        if (this.loadEditMonthYear === UsageTypes.ANNUAL && !isNumber(this.loadEditYearPowerConsumption)) {
            message.error('请填写年度用电量');
            return false;
        }

        if (this.loadEditMonthYear === UsageTypes.MONTHLY && !this.loadEditMonthsPowerConsumption.every(isNumber)) {
            message.error('请填写完整12个月用电量');
            return false;
        }

        if (this.enableLoadEditWeekend && !isNumber(this.loadEditWeekendPercent)) {
            message.error('请填写周末用电量占比');
            return false;
        }

        return true;
    }

    // 用于强制跟新的时间
    updateTime = new Date().getTime();

    // 当前步骤  todo 修改初始数据
    currentStep = 0;

    // 设置当前步骤
    setCurrentStep(currentStep) {
        this.currentStep = currentStep;
    }

    // 登陆模态框
    loginModalVisible = false;

    // 是否登录
    isLogin = false;

    // 用户信息
    profile = null;

    /** 电价信息列表
     * @type  {{hour: number, price: number, type: number}[][]}
     */
    prices = [];

    /** 电价应用月份
     * @type number[]
     */
    elecPriceMonths = new Array(12).fill(0);

    setElecPriceMonthsOnlyShow(elecIndex, index) {
        const elecPriceMonths = this.elecPriceMonths.slice();
        if (isArray(elecIndex)) {
            this.elecPriceMonths = elecIndex;
        }
        if (isArray(index)) {
            // 清空
            elecPriceMonths.forEach((item, i) => {
                if (item === elecIndex) {
                    elecPriceMonths[i] = 0;
                }
            })
            index.forEach(i => {
                elecPriceMonths[i] = elecIndex;
            })
            this.elecPriceMonths = elecPriceMonths;
            return;
        }
        this.elecPriceMonths[index] = elecIndex;
    }

    setElecPriceMonths(elecIndex, index) {
        const elecPriceMonths = this.elecPriceMonths.slice();
        if (isArray(elecIndex)) {
            this.elecPriceMonths = elecIndex;
            this.priceTemplateIds = [];
        }
        if (isArray(index)) {
            // 清空
            elecPriceMonths.forEach((item, i) => {
                if (item === elecIndex) {
                    elecPriceMonths[i] = 0;
                }
            })
            index.forEach(i => {
                elecPriceMonths[i] = elecIndex;
            })
            console.log('setElecPriceMonths', elecIndex, index, elecPriceMonths);
            this.elecPriceMonths = elecPriceMonths;
            this.priceTemplateIds[elecIndex] = null;
            return;
        }
        this.elecPriceMonths[index] = elecIndex;
        this.priceTemplateIds[elecIndex] = null;
    }


    initStructuredPrices = [];

    setInitStructuredPrices(initStructuredPrices, options = {
        syncStructuredPrices: true,
        elecIndex: 0,
        // 完整替换
        replace: false,
    }) {
        const {syncStructuredPrices = true, elecIndex, replace = false} = options;
        if (replace) {
            this.initStructuredPrices = initStructuredPrices;
            if (syncStructuredPrices) this.structuredPrices = initStructuredPrices;
        } else {
            this.initStructuredPrices[elecIndex] = initStructuredPrices;
            console.log('setInitStructuredPrices', initStructuredPrices, elecIndex, this.initStructuredPrices)
            if (syncStructuredPrices) this.structuredPrices[elecIndex] = initStructuredPrices;
        }
    }

    /** 结构化电价描述
     * @type {{
     *     [key: string]: {
     *         price: number,
     *         type: number,
     *         hours: number[][],
     *     }
     * }}[]
     */
    structuredPrices = [{}];

    setStructuredPrices(key, structuredPrice, index) {
        this.structuredPrices[index][key] = structuredPrice;
    }

    addStructuredPrices() {
        this.structuredPrices.push(this.structuredPrices[0] || {});
        this.prices.push(this.prices[0] || []);
    }

    removeStructuredPrices(index) {
        this.structuredPrices.splice(index, 1);
        this.prices.splice(index, 1);
    }

    // 电价模板id
    priceTemplateIds = [];

    // 选择城市id
    cityId = null;

    // 负荷模板id
    loadCurveId = null;

    // 负荷数据
    loadData = [];

    // 充放功率曲线
    pcsPowers = [];

    setPcsPowers(pcsPowers) {
        this.pcsPowers = pcsPowers;
    }

    // SOC电池充放状态曲线
    socs = [];

    setSocs(socs) {
        this.socs = socs;
    }


    // 调整用电量
    adjustingPowerConsumption = 100;

    // 设置调整用电量
    setAdjustingPowerConsumption(adjustingPowerConsumption) {
        this.adjustingPowerConsumption = adjustingPowerConsumption;
    }

    // 变压器容量
    transformerCapacity = 0;


    // 设置变压器容量
    setTransformerCapacity(transformerCapacity) {
        this.transformerCapacity = transformerCapacity;
    }

    // 防逆流
    antiReflux = 1;

    // 设置防逆流
    setAntiReflux(antiReflux) {
        this.antiReflux = antiReflux;
    }

    // 防过载
    antiOverload = 1;

    // 设置防过载
    setAntiOverload(antiOverload) {
        this.antiOverload = antiOverload;
    }

    // 充放电次数，0: 一充一放，1: 两充一放
    mode = 0;

    // 设置充放电次数
    setMode(mode) {
        this.mode = mode;
    }

    // 平段充电，0: 不允许，1: 允许
    flatCharging = 0;

    // 设置平段充电
    setFlatCharging(flatCharging) {
        this.flatCharging = flatCharging;
    }

    // 系统单瓦造价
    systemTileCost = 1;

    // 设置系统单瓦造价
    setSystemTileCost(systemTileCost) {
        this.systemTileCost = systemTileCost;
    }

    // 可放电深度
    dischargeableDepth = 90;

    // 设置可放电深度
    setDischargeableDepth(dischargeableDepth) {
        this.dischargeableDepth = dischargeableDepth;
    }

    //电池充放效率
    fillChargeEffic = 95;

    //设置电池充放效率
    setFillChargeEffic(fillChargeEffic) {
        this.fillChargeEffic = fillChargeEffic;
    }

    // PCS效率
    pcsEffic = 98;

    // 设置PCS效率
    setPcsEffic(pcsEffic) {
        this.pcsEffic = pcsEffic;
    }

    //其他效率
    otherEffic = 100;

    //设置其他效率
    setOtherEffic(otherEffic) {
        this.otherEffic = otherEffic;
    }

    // 自耗电量
    selfPowerConsumption = 1;

    // 设置自耗电量
    setSelfPowerConsumption(selfPowerConsumption) {
        this.selfPowerConsumption = selfPowerConsumption;
    }

    // 年可用天数
    effectiveDays = 300;

    // 设置年可用天数
    setEffectiveDays(effectiveDays) {
        this.effectiveDays = effectiveDays;
    }


    constructor() {
        makeAutoObservable(this);
    }

    // 设置登陆模态框是否可见
    setLoginModalVisible(visible) {
        this.loginModalVisible = visible;
    }

    // 设置是否登录
    setIsLogin(isLogin) {
        this.isLogin = isLogin;
    }

    // 设置用户信息
    setProfile(profile) {
        profile.location = [profile.province, profile.city];
        this.profile = profile;
    }

    setPrices(price, index) {
        if (index === undefined) return;
        // 深度观测prices是否出现变化
        if (JSON.stringify(price) === JSON.stringify(this.prices[index] || {})) {
            return;
        }
        // if (this.prices?.[index] && !(this.prices?.[index]?.length === 0)) {
        //     this.priceTemplateIds[index] = null;
        // }
        console.log('setPrices', price, index)
        this.prices[index] = price;

    }

    setPriceTemplateIds(priceTemplateIds, index) {
        if (isArray(priceTemplateIds)) {
            this.priceTemplateIds = priceTemplateIds;
            return;
        }

        if (index === undefined) return;

        this.priceTemplateIds[index] = priceTemplateIds;
    }

    // 是否存在电价模版id
    hasPriceTemplateId(index) {
        const priceTemplateIds = this.priceTemplateIds[index];
        return priceTemplateIds !== null && priceTemplateIds !== undefined;
    }

    // 设置城市id
    setCityId(cityId) {
        this.cityId = cityId;
    }

    // 设置负荷模板id
    setLoadCurveId(loadCurveId) {
        this.loadCurveId = loadCurveId;
    }

    // 存在负荷模板id
    hasLoadCurveId() {
        return this.loadCurveId !== null;
    }

    // 设置负荷数据
    setLoadData(loadData) {
        this.loadData = loadData;
    }

    // 存在负荷数据
    hasLoadData() {
        return this.loadData.length > 0;
    }

    // 清理负荷相关数据
    clearLoadData() {
        this.loadCurveId = null;
        this.loadData = [];
    }

    /**
     * [省code, 市code, 区code]
     * @type {[string, string, string]}
     */
    selectedCity = [];

    // 设置选择城市
    setSelectedCity(selectedCity) {
        this.selectedCity = selectedCity;
    }

    // 项目名称
    name = '';

    // 设置项目名称
    setProjectName(name) {
        this.name = name;
    }

    // 指纹id
    fingerprintId = "";

    // 设置指纹id
    setFingerprintId(fingerprintId) {
        this.fingerprintId = fingerprintId;
    }

    // 提交进度模态框是否可见
    submitModalVisible = false;

    // 设置提交进度模态框是否可见
    setSubmitModalVisible(visible) {
        this.submitModalVisible = visible;
    }

    // 提交进度是否完成
    submitCompleted = false;

    // 设置提交进度是否完成
    setSubmitCompleted(completed) {
        this.submitCompleted = completed;
    }

    // 项目列表
    projectList = [];

    // 设置项目列表
    setProjectList(projectList) {
        this.projectList = projectList;
    }

    // 初始化数据,入参可能为空
    initData(data) {
        if (data) {
            console.log('initData', data);
            // this.setPrices(data?.prices || []);
            if (data?.elecPriceTemplateIds) {
                const elecPriceTemplateIds = data?.elecPriceTemplateIds?.split(',') ?? [];
                this.setPriceTemplateIds(elecPriceTemplateIds);
                elecPriceTemplateIds.forEach(
                    (id, index) => {
                        getElecPriceById(id).then(
                            res => {
                                console.log('getElecPriceById', res);
                                const data = res.data;
                                if (data?.data?.data.id) {
                                    planStore.setPriceTemplateIds(data.data.data.id, index);
                                    planStore.setInitStructuredPrices(data.data, {
                                        elecIndex: index,
                                    });
                                    // 设置电价数据
                                    try {
                                        const curPrices = JSON.parse(data.data.data.data);
                                        let c = curPrices;
                                        // curPrices 中基于hour从小到大排序
                                        curPrices.sort((a, b) => a.hour - b.hour);
                                        if (curPrices[0].hour === 1) {
                                            c = curPrices.map(
                                                (price) => ({
                                                    ...price,
                                                    hour: price.hour - 1,
                                                })
                                            )
                                        }
                                        planStore.setPrices(c, index);

                                        // 初始化月份
                                        data?.data?.data?.months?.split(',').forEach(month => {
                                            const mouthNum = parseInt(month) - 1;
                                            planStore.setElecPriceMonths(index, mouthNum);
                                        })
                                    } catch (e) {
                                        console.log('电价数据解析失败', e);
                                    }
                                }
                            }
                        ).catch(
                            err => console.error(err)
                        )
                    }
                )
            }
            this.setCityId(data?.cityId || null);
            this.setLoadCurveId(data?.loadCurveId || null);
            // this.setLoadData(data?.loadData || []);
            this.setAdjustingPowerConsumption(data?.adjustingPowerConsumption * 100 || 100);
            this.setTransformerCapacity(data?.transformerCapacity || 0);
            this.setAntiReflux(data?.antiReflux || 1);
            this.setAntiOverload(data?.antiOverload || 1);
            this.setMode(data?.mode || 0);
            this.setFlatCharging(data?.flatCharging || 0);
            this.setSystemTileCost(data?.systemTileCost || 0);
            this.setFillChargeEffic(data?.fillChargeEffic * 100 || 100);
            this.setPcsEffic(data?.pcsEffic * 100 || 100);
            this.setOtherEffic(data?.otherEffic * 100 || 100);
            this.setSelfPowerConsumption(data?.selfPowerConsumption || 0);
            this.setEffectiveDays(data?.effectiveDays || 300);
            this.setProjectName(data?.name || '');
            this.setSelectedCity([data?.province, data?.city]);
            this.updateTime = new Date().getTime();
        }
    }

    /**
     * 储能站点数据规划结果
     * @type {{
     *     "stationId": string
     *     "recommendBatteryCapacity": number
     *       "recommendPcsEffic": number
     *        "paybackTime": number
     *        "averageCyclesPerday": number
     * }}
     */
    stationResult = null;

    // 设置规划结果
    setStationResult(stationResult) {
        this.stationResult = stationResult;
    }
    

    // 是否存在规划结果
    hasStationResult() {
        return this.stationResult !== null;
    }

    // 清理规划结果
    clearStationResult() {
        this.stationResult = null;
    }
    
    isCustomCompute=false;
    setIsCustomCompute(isCustomCompute){
        this.isCustomCompute=isCustomCompute;
    }

    isShowEvaluate=false;
    setIsShowEvaluate(isShowEvaluate){
        this.isShowEvaluate=isShowEvaluate;
    }

    comment="test..."


}

export const planStore = new PlanStore();
