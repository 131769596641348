import React from 'react';
import {Form, Row, Col, InputNumber, Card} from 'antd';
import {planStore} from "../../../store";
import {observer} from "mobx-react";

const HourlyLoadInput = () => {


    // 更新指定时段的负荷值
    const handleChange = (value, index) => {
        planStore.updateLoadEditDay24h(index, value);
    };

    return (
        <Form layout="vertical">
            <Card bordered={false} style={{maxWidth: '1200px', margin: '20px auto'}}>
                <Row gutter={[8, 8]} justify="start">
                    {planStore.loadEditDay24h.map((load, index) => (
                        <Col span={6} key={index}>
                            <Form.Item
                                label={`${index}-${index + 1}时`}
                                name={`hour-${index}`}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                            >
                                <div style={{display: "none"}}>{load}</div>
                                <InputNumber
                                    min={0}
                                    max={10000}
                                    step={100}
                                    value={load}
                                    onChange={(value) => handleChange(value, index)}
                                    placeholder={`负荷值`}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Card>
        </Form>
    );
};

export default observer(HourlyLoadInput);
