import React, {useState, useEffect, useMemo} from 'react';
import {Select, Tag} from 'antd';
import {planStore} from "../../../store";
import {toJS} from "mobx";
import {observer} from "mobx-react";
import {CloseCircleOutlined} from "@ant-design/icons";

const {Option} = Select;

// 从已选月份计算连续区间
const calculateRanges = (months) => {
    if (!months.length) return [];
    const sortedMonths = months.map(month => parseInt(month)).sort((a, b) => a - b);
    let ranges = [];
    let start = sortedMonths[0];
    let end = start;

    for (let i = 1; i < sortedMonths.length; i++) {
        if (sortedMonths[i] === end + 1) {
            end = sortedMonths[i];
        } else {
            ranges.push(start === end ? `${start}月` : `${start}~${end}月`);
            start = sortedMonths[i];
            end = start;
        }
    }
    ranges.push(start === end ? `${start}月` : `${start}~${end}月`);
    return ranges;
};


const MonthSelectorOnlyShow = (props) => {

    const {index, disable} = props;

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [monthRanges, setMonthRanges] = useState([]);


    // 基于全局状态同步月份信息
    const syncStoreElecMonths = (newSelectedMonths) => {
        const clearElectPriceMonths = planStore.elecPriceMonths.map((item, i) => {
            if (item === index) {
                if (newSelectedMonths.includes((i + 1) + '')) {
                    return item;
                } else {
                    return 0;
                }
            }
            return item;
        });

        planStore.setElecPriceMonths(clearElectPriceMonths);
    }

    useEffect(() => {
        const elecPriceMonths = toJS(planStore.elecPriceMonths);
        // 判断具体是哪套电价
        const curSelectedMonths = elecPriceMonths?.map((item, i) => {
            if (item === index) {
                return (i + 1) + '';
            }
        }).filter(Boolean);

        console.log('selectedMonths', selectedMonths, index, elecPriceMonths)

        // 如果curSelectedMonths与当前selectedMonths内容一致，则不更新
        !!selectedMonths && JSON.stringify(curSelectedMonths) !== JSON.stringify(selectedMonths)
        && setSelectedMonths(curSelectedMonths);
    }, [planStore.elecPriceMonths]);

    useEffect(() => {
        setMonthRanges(calculateRanges(selectedMonths));
        planStore.setElecPriceMonthsOnlyShow(index, selectedMonths.map(month => parseInt(month) - 1));
    }, [selectedMonths]);


    const handleChange = (value) => {
        setSelectedMonths(value);
    };

    const handleTagClose = (startValue) => {
        const removedRange = monthRanges.find(range => range.startsWith(startValue));
        const range = removedRange.replace('月', '').split('~').map((item) => parseInt(item) - 1);
        const newSelectedMonths = selectedMonths.filter(month => {
            const monthNumber = parseInt(month) - 1;
            return range.length === 1 ? monthNumber !== range[0] : monthNumber < range[0] || monthNumber > range[1];
        });
        setSelectedMonths(newSelectedMonths.map(String));
    };

    const tagRender = (props) => {
        const {label, value, closable} = props;

        console.log('tagRender', index, label, value, closable, monthRanges);

        if (!value) return null;

        // 计算每个单独的标签应该显示的范围文本
        const displayLabel = monthRanges.find(range => range.startsWith(value) || range.endsWith(value?.split('~')?.[1]));

        return (
            <>
                {
                    !!displayLabel && <Tag
                        key={label} // 使用独特的label作为key
                        color="#1F98F1"
                        closable={index !== 0}
                        closeIcon={<CloseCircleOutlined style={{color: "#fff"}}/>}
                        onClose={() => handleTagClose(value)}
                        style={{fontSize: '12px', marginRight: '8px'}}
                    >
                        {displayLabel}
                    </Tag>
                }
            </>
        );
    };

    return (
        <div>
            <Select
                disabled={ disable || index === 0}
                mode="multiple"
                placeholder="月份"
                value={selectedMonths}
                onChange={handleChange}
                style={{margin: 0, padding: 0, maxWidth: 760, minWidth: 100}}
                dropdownMatchSelectWidth={false}
                tagRender={tagRender}
            >
                {Array.from({length: 12}, (_, i) => (
                    <Option
                        key={i + 1}
                        value={String(i + 1)}
                    >
                        {`${i + 1}月`}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default observer(MonthSelectorOnlyShow);
