import './style.scss';
import bg from '../../assets/images/item.webp'
import {Button, message, Popconfirm} from "antd";
import {useNavigate} from "react-router-dom";
import {deleteStation} from "../../services/api";

// 通过当前时间和更新时间计算出类似 5分钟前，10分钟前，半小时前，1小时前，1天前，1周前，1月前，1年前
function formatTime(time) {
    const currentTime = new Date().getTime();
    const updateTime = new Date(time).getTime();
    const diffTime = currentTime - updateTime;
    if (diffTime < 1000 * 60) {
        return '刚刚';
    } else if (diffTime < 1000 * 60 * 60) {
        return `${Math.floor(diffTime / 1000 / 60)}分钟前`;
    } else if (diffTime < 1000 * 60 * 60 * 24) {
        return `${Math.floor(diffTime / 1000 / 60 / 60)}小时前`;
    } else if (diffTime < 1000 * 60 * 60 * 24 * 7) {
        return `${Math.floor(diffTime / 1000 / 60 / 60 / 24)}天前`;
    } else if (diffTime < 1000 * 60 * 60 * 24 * 30) {
        return `${Math.floor(diffTime / 1000 / 60 / 60 / 24 / 7)}周前`;
    } else if (diffTime < 1000 * 60 * 60 * 24 * 365) {
        return `${Math.floor(diffTime / 1000 / 60 / 60 / 24 / 30)}月前`;
    } else {
        return `${Math.floor(diffTime / 1000 / 60 / 60 / 24 / 365)}年前`;
    }
}

function ProjectItem({
                         project,
                         fetchProjects,
                     }) {
    // 路由跳转
    const navigate = useNavigate();

    return (
        <div className="project-item-container">
            <div className="project-item-img">
                <img src={bg} alt=""/>
            </div>
            <div className="project-item">
                <div className="project-item__name">{project.name || '暂无姓名'}</div>
                <div className="project-item__desc">{
                    // project.description || '暂无描述'
                }</div>
            </div>
            <div className="project-item__btn__container">
                {/*更新时间*/}
                <div className="project-item__update-time">{
                    formatTime(
                        project.updatedTime ??
                        project.createdTime ??
                        new Date())
                }</div>
                <div>
                    <div className="project-item__btn">
                        <Button type="primary" onClick={() => {
                            navigate(`/?stationId=${project.id}`)
                        }}>查看</Button>
                    </div>
                    <div className="project-item__btn">
                        <Popconfirm
                            title="删除这个项目"
                            description="确认删除这个项目吗？"
                            onConfirm={() => {
                                deleteStation(project.id).then(
                                    res => {
                                        console.log('deleteStation', res);
                                        if (res.data.code === 0) {
                                            message.success('删除成功');
                                            fetchProjects();
                                        }
                                    }
                                ).catch(
                                    err => {
                                        console.log('deleteStation', err);
                                    }
                                )
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Button danger>删除</Button>
                        </Popconfirm>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ProjectItem;
