import {Rate, Tag, Input, Button, message, Divider, Form} from "antd";
import {useState} from "react";
import './style.scss';
import {saveEvaluationData} from "../../services/api";
import {planStore} from "../../store";
import generateFingerprintUUID from "../../utils/fingerprint";

const {TextArea} = Input;

// taggroup默认值
const tagGroup = [
    {
        id: '1',
        name: '导航体验',
    },
    {
        id: '2',
        name: '可视化',
    },
    {
        id: '3',
        name: '规划算法',
    },
    {
        id: '4',
        name: '交互帮助',
    },
];

function Evaluate() {
    const [form] = Form.useForm();

    // 评分组件
    const [value, setValue] = useState(2.5);

    // 评论内容
    const [comment, setComment] = useState('');

    // 电话
    const [phone, setPhone] = useState('');

    // 邮箱
    const [email, setEmail] = useState('');

    return (
        <div className="evaluate-container">
            <Form form={form}>
            <div className="evaluate-container__item">
                <div className="evaluate-container__item__title">工具评分：</div>
                <div className="evaluate-container__item__content">
                    <Form.Item>
                    <Rate allowHalf defaultValue={2.5} onChange={(value) => setValue(value)}/>
                    </Form.Item>
                </div>
            </div>
            <div className="evaluate-container__item">
                <div className="evaluate-container__item__title">意见建议：</div>
                <div className="evaluate-container__item__content">
                    <Form.Item name="comment" rules={[{max: 200,required: true, message: '最多输入200个字符'}]}>
                        <TextArea
                            placeholder="您的建议如被采纳，我们将联系您并送上则鸣专属礼品。"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{width: 280, marginTop: 5}}
                            autoSize={{minRows: 2, maxRows: 4}}/>
                    </Form.Item>
                    <div className="evaluate-container__item__title">联系方式：</div>
                    <div>
                    <Form.Item name="phone" rules={[{message: '请输入电话号码'},{  
                        pattern: /^(13|14|15|17|18)\d{9}$|^\d{3}[- ]?\d{7,8}$/,  
                        message: '请输入正确的电话号码',  
                    }]}>
                    <Input placeholder="固定电话或者手机号" style={{width: 280}} onChange={
                            (e) => {
                                setPhone(e.target.value);
                            }
                        }/>                        
                    </Form.Item>
                    </div>
                    <div>
                        <Form.Item name="email" rules={[{type: 'email',message:"请输入正确的邮箱"}]}>
                        <Input placeholder="邮箱" style={{width: 280}}
                               onChange={
                                   (e) => {
                                       setEmail(e.target.value);
                                   }
                               }
                        />
                        </Form.Item>
                    </div>

                </div>
            </div>
            <div className="evaluate-container__btn">
                <Button type="primary" style={{marginTop: 5}} onClick={
                    async () => {
                        try {
                            const values = await form.validateFields();
                            console.log('Success:', values);
                            generateFingerprintUUID().then(uuid => {
                                saveEvaluationData({
                                    score: value,
                                    content: comment,
                                    traceId: uuid,
                                    phone,
                                    email
                                });
                                console.log('提交评价', value, comment);
                                message.success('提交成功');
                                planStore.setIsShowEvaluate(false);
                            })
                        } catch (errorInfo) {
                            console.log('Failed:', errorInfo);
                        }                        

                    }
                }>提交</Button>
            </div>            
            </Form>
        </div>
    );
}

export default Evaluate;
