import React, { useState, useEffect, useMemo } from "react";
import { planStore } from "../../../store";
import { observer } from "mobx-react";
import { Menu, Dropdown, Button, message, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";

const LoadEditorSelector = () => {
  const { enableLoadEdit = false } = planStore;

  const handleChange = (value) => {
    planStore.setEnableLoadEdit(!!value);
  };

  // 处理菜单项点击事件
  const handleMenuClick = (e) => {
    // 根据选中的key更新状态，并给出反馈
    if (e.key === "1") {
      planStore.setEnableLoadEdit(true);
      // message.info('已选择: 上传文件');
    } else if (e.key === "2") {
      planStore.setEnableLoadEdit(false);
      // message.info('已选择: 手动输入');
    }
  };

  // 创建菜单
  const items = [
    {
      key: "2",
      label: "使用负荷模板",
    },
    {
      key: "1",
      label: "上传负荷文件",
    },
  ];
 
  return (
    <div>
      <Dropdown.Button
        icon={<DownOutlined />}
        style={{ width: 300 }}
        menu={{
            items,
            selectable: true,
            onClick: handleMenuClick,
        }}
      >
        数据来源：{enableLoadEdit ? "上传负荷文件" : "使用负荷模板"}{" "}
      </Dropdown.Button>
    </div>
  );
};

export default observer(LoadEditorSelector);
