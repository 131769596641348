export function EvaluateIcon() {
    return (
        <svg className="icon" viewBox="0 0 1024 1024" width="100%" height="100%">
            <path
                d="M972.737958 367.101443h-321.82406L492.277072 22.646788 342.712727 367.101443H20.90486l267.421568 240.228419-77.053329 326.329937 281.003973-194.898406 303.675052 185.84212-90.635734-348.992919z"
                fill="#FFD28F"></path>
            <path
                d="M211.273099 933.659799l284.230554-421.66992 300.448471 412.613634-303.675052-185.84212zM20.90486 367.101443l474.598793 144.888436-207.177225 95.339983zM972.737958 367.101443l-477.234305 144.888436 209.812737 63.620715z"
                fill="#EBC184"></path>
            <path
                d="M495.503653 511.989879V29.658629L342.712727 367.101443zM495.503653 511.989879l155.410245-144.888436h321.82406z"
                fill="#FFFFFF" opacity=".34"></path>
            <path d="M342.712727 367.101443H20.90486l474.598793 144.888436z" fill="#FFFFFF" opacity=".34"></path>
            <path d="M492.277072 738.761393l3.226581-226.771514-284.230554 421.66992z" fill="#D9B27A"></path>
            <path d="M95.61821 395.735826l344.00933 63.264455 55.876113-360.895236v413.884834z" fill="#FFF3E0"></path>
            <path
                d="M211.273099 961.30637a16.201725 16.201725 0 0 1-15.760448-19.914113l74.846947-316.978118L10.07942 390.598397a16.193628 16.193628 0 0 1 10.821392-28.241686h311.184847l145.337808-334.706092a16.185531 16.185531 0 0 1 29.561468-0.323872l154.296933 335.029964h311.45609a16.193628 16.193628 0 0 1 9.959081 28.966351l-118.703339 92.550631a16.193628 16.193628 0 1 1-19.918162-25.541399l81.559206-63.588328H650.917946a16.193628 16.193628 0 0 1-14.707862-9.420643l-143.491735-311.569446L357.566332 385.003499a16.193628 16.193628 0 0 1-14.853605 9.744515H63.162132l235.989736 211.994829a16.197676 16.197676 0 0 1 4.939057 15.768545l-67.353346 285.234559 182.967751-126.905412a16.193628 16.193628 0 0 1 18.456687 26.614227l-217.662599 150.965094a16.177434 16.177434 0 0 1-9.226319 2.886514z"
                fill="#6E6E96"></path>
            <path
                d="M735.962831 750.898517m-234.15176 0a234.15176 234.15176 0 1 0 468.303519 0 234.15176 234.15176 0 1 0-468.303519 0Z"
                fill="#BBEDCE" p-id="5092"></path>
            <path
                d="M735.962831 1001.243905c-138.042579 0-250.349436-112.302808-250.349436-250.345388s112.306857-250.345388 250.349436-250.345388 250.345388 112.302808 250.345387 250.345388-112.302808 250.345388-250.345387 250.345388z m0-468.30352c-120.185056 0-217.962181 97.773076-217.962181 217.958132s97.777124 217.958132 217.962181 217.958132 217.958132-97.773076 217.958132-217.958132-97.773076-217.958132-217.958132-217.958132z"
                fill="#6E6E96"></path>
            <path d="M620.23507 722.519184h238.856009v48.580884h-238.856009z" fill="#6E6E96"></path>
            <path d="M715.372633 627.381622h48.580883v238.856008h-48.580883z" fill="#6E6E96"></path>
        </svg>
    );
}
