import './style.scss'
function TitleBoard({
    title,
    subTitle,
                    }) {
  return (
    <div className="title-board">
        {/*<div className="title-board__subtitle">{subTitle} ></div>*/}
        {/*<div className="title-board__divider"></div>*/}
        <div className="title-board__title">{title}</div>
    </div>
  );
}

export default TitleBoard;
