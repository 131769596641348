import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1>隐私政策</h1>
            <p>欢迎使用则鸣新能源的储能规划工具！我们致力于保护您的隐私和数据。本隐私政策旨在透明地说明我们如何收集、存储、使用和保护您的个人信息，并确保我们的做法符合中国的法律法规。</p>
            <h2>1. 信息收集</h2>
            <p>我们收集的信息主要包括：</p>
            <ul>
                <li><strong>个人识别信息</strong>：如姓名、联系方式、电子邮件地址等，您在注册或使用我们的服务时提供。</li>
                <li><strong>使用数据</strong>：包括但不限于您的使用频率、访问时间、使用的功能等信息。</li>
                <li><strong>设备信息</strong>：包括设备型号、操作系统版本、设备设置、IP地址等信息。</li>
            </ul>
            <h2>2. 使用信息的目的</h2>
            <p>收集的信息将用于：</p>
            <ul>
                <li>优化储能规划的功能和性能。</li>
                <li>提供客户支持和解决技术问题。</li>
                <li>进行内部研究及开发，改进我们的服务。</li>
                <li>法律法规所要求的用途。</li>
            </ul>
            <h2>3. 信息共享与披露</h2>
            <p>我们承诺不会将您的个人信息出售给任何第三方。以下情况除外，我们可能会共享或披露您的信息：</p>
            <ul>
                <li>经您明确同意的共享。</li>
                <li>法律法规要求，或应政府部门或司法机关的要求进行披露。</li>
                <li>与我们的合作伙伴共享，如为了提供技术支持或其他服务时，我们将确保他们同样遵守本隐私政策。</li>
            </ul>
            <h2>4. 信息安全</h2>
            <p>我们采取各种安全措施来保护您的个人信息，包括服务器加密存储、访问控制等。尽管如此，互联网上的数据传输无法保证是完全安全的，我们会尽最大努力保护您的信息安全。</p>
            <h2>5. 用户权利</h2>
            <p>您可以随时查看、更改或删除您的个人信息。如果您有这些需求，请联系我们的客服。</p>
            <h2>6. 政策更新</h2>
            <p>我们可能不时更新本隐私政策以反映法律、技术或业务的变化。更新后的政策将在本网页上公布，并在生效前通过电子邮件或网站通知您。</p>
            <h2>7. 联系我们</h2>
            <p>如果您对本隐私政策有任何疑问或顾虑，请通过以下方式联系我们：</p>
            <ul>
                <li>电话： --</li>
                <li>地址： 北京市大兴区北兴路东段2号星光影视园新媒体大厦19号楼西区801室</li>
            </ul>
            <p>感谢您的信任与支持！</p>
        </div>
    );
}

export default PrivacyPolicy;
