import React, {useState} from 'react';
import {Form, Input, Radio, Row, Col, InputNumber, Tooltip} from 'antd';
import {observer} from "mobx-react";
import {planStore, UsageTypes} from "../../../store";
import {InfoCircleOutlined} from "@ant-design/icons";

const EnergyConsumptionInput = () => {

    // 更新时间周期选择（年或月）
    const handleTimePeriodChange = e => {
        planStore.setLoadEditMonthYear(e.target.value);
    };

    return (
        <Form layout="vertical">
            <Form.Item
                // label="能耗 (KWH)"
            >
                <Radio.Group
                    defaultValue={planStore.loadEditMonthYear}
                    buttonStyle="solid"
                    onChange={handleTimePeriodChange}
                >
                    <Radio.Button value={UsageTypes.ANNUAL}>年度</Radio.Button>
                    <Radio.Button value={UsageTypes.MONTHLY}>月度</Radio.Button>
                </Radio.Group>
                <div style={{marginTop: '10px'}}>
                <span className="second-step__slider-label">请输入全年用电量 (KWH)
                <Tooltip title="全年用电量"><InfoCircleOutlined style={{margin: '0px 4px'}}/></Tooltip>
                </span>
                    {planStore.loadEditMonthYear === UsageTypes.ANNUAL ? (
                        <Form.Item
                            style={{marginBottom: 0}}
                        >
                            <InputNumber
                                min={0}
                                step={100}
                                onChange={(value) => planStore.setLoadEditYearPowerConsumption(
                                    value,
                                )}
                                placeholder={`全年用电量`}
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    ) : (
                        <Row gutter={[16, 16]}>
                            {/*        {*/}
                            {/*            planStore.loadEditMonthsPowerConsumption.map((_, i) => (*/}
                            {/*            <Col span={8} key={i}>*/}
                            {/*        <Form.Item*/}
                            {/*            label={`第 ${i + 1} 月能耗 (KWH)`}*/}
                            {/*            style={{marginBottom: 0}}  // 移除输入框下方的额外空间*/}
                            {/*        >*/}
                            {/*            <Input placeholder={`请输入第${i + 1}月能耗`}/>*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*))*/}
                            {/*        }*/}
                            {
                                planStore.loadEditMonthsPowerConsumption.map((power, index) => (
                                    <Col span={8} key={index}>
                                        <Form.Item
                                            label={`第 ${index + 1} 月能耗 (KWH)`}
                                            style={{marginBottom: 0}}
                                        >
                                            <InputNumber
                                                min={0}
                                                step={100}
                                                value={power}
                                                onChange={(value) => planStore.updateLoadEditMonthsPowerConsumption(
                                                    index,
                                                    value,
                                                )}
                                                placeholder={`${index + 1} 月能耗`}
                                                style={{width: '100%'}}
                                            />
                                        </Form.Item>
                                    </Col>
                                ))
                            }
                        </Row>
                    )}
                </div>
            </Form.Item>
        </Form>
    );
};

export default observer(EnergyConsumptionInput);
