import { useState, useEffect } from 'react';
import { autorun } from 'mobx';

export function useMobxValue(selector) {
    const [value, setValue] = useState(selector());

    useEffect(() => {
        // 使用autorun来监听任何数据变化，并更新状态
        const disposer = autorun(() => {
            const newValue = selector();
            setValue(newValue);
        });

        return () => disposer();
    }, [selector]); // 依赖于选择器函数

    return value;
}
