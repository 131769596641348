import TitleBoard from "../../title-board";
import ChartTopCard from "../second-step/chart-top-card";
import React from "react";
import QrcodeImage from "../../../assets/images/qrcode.jpg";
import StdLogoImage from "../../../assets/images/std-logo.png";

import './style.scss';
import ElectrovalenceChart from "../first-step/electrovalence-chart";
import {planStore} from "../../../store";
import {observer} from "mobx-react";
import LoadCurveChart from "../second-step/load-curve-chart";
import {Col, Divider, Row, Statistic, Typography, Watermark} from "antd";
import DividerTitle from "../../divider-title";
import ResultTag from "../../ResultTag";
import MonthSelectorOnlyShow from "../first-step/MonthSelectorOnlyShow";

const {Title, Paragraph, Text, Link} = Typography;

function ViewResults() {
    return (
        <div id='divToPrint' className="main-content-width">
            <TitleBoard subTitle={'则鸣新能源'} title={'则鸣ZESS储能规划分析报告'}></TitleBoard>

            <Watermark content="www.zenergytech.cn">
                <div className="br-8" />
                
                {planStore.isCustomCompute?
                    <div>
                        <DividerTitle title={'系统配置'}></DividerTitle>
                        <div className="chart-top-card-group">
                            <ChartTopCard title={'电池容量值'} value={planStore.stationResult?.recommendBatteryCapacity}
                                        unit="KWH"/>
                            <ChartTopCard title={'PCS功率值'} value={planStore.stationResult?.recommendPcsEffic} unit="KW"/>
                            <ChartTopCard title={'预计投资回报年限'} value={planStore.stationResult?.paybackTime} unit="年"/>
                            <ChartTopCard title={'平均日充放电次数'} value={planStore.stationResult?.averageCyclesPerday}
                                        unit="次"/>
                        </div>
                    </div>
                :
                    <div>
                    <DividerTitle title={'系统建议配置'}></DividerTitle>
                        <div className="chart-top-card-group">
                            <ChartTopCard title={'电池容量建议值'} value={planStore.stationResult?.recommendBatteryCapacity}
                                        unit="KWH"/>
                            <ChartTopCard title={'PCS功率建议值'} 
                                value={
                                    planStore.stationResult?.recommendPcsEfficMin+"~"+planStore.stationResult?.recommendPcsEfficMax
                                } unit="KW"/>
                            <ChartTopCard title={'预计投资回报年限'} value={planStore.stationResult?.paybackTime} unit="年"/>
                            <ChartTopCard title={'平均日充放电次数'} value={planStore.stationResult?.averageCyclesPerday}
                                        unit="次"/>
                        </div>
                    </div>
                }

                <div className="br-8" />
                <DividerTitle title={'系统参数'}></DividerTitle>
                <div className="system-param-group">
                    <Row gutter={20}>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="未来负荷增长"
                                       value={planStore.adjustingPowerConsumption}
                                       suffix={"%"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="满充满放天数" value={planStore.effectiveDays} suffix={"天"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="系统单瓦造价"
                                       value={planStore.systemTileCost}
                                       suffix={"元/Wh"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="可放电深度" value={planStore.dischargeableDepth} suffix={"%"}/>
                        </Col>
                    </Row>
                    <div className="br-8" />
                    <Row gutter={20}>
                        <Col span={6} className={"centered-content"}>
                            <Statistic
                                title="电池充放效率"
                                value={planStore.fillChargeEffic}
                                suffix={"%"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="PCS效率" value={planStore.pcsEffic} suffix={"%"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="其他效率" value={planStore.otherEffic} suffix={"%"}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="自耗电量" value={planStore.selfPowerConsumption} suffix={"KWh/天"}/>
                        </Col>
                    </Row>
                    <div className="br-8" />
                    <Row gutter={20}>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="防过载" value={planStore.antiOverload === 1 ? '是' : '否'}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="防逆流" value={planStore.antiReflux === 1 ? '是' : '否'}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="充放电次数" value={planStore.mode === 1 ? '两充两放' : '一充一放'}/>
                        </Col>
                        <Col span={6} className={"centered-content"}>
                            <Statistic title="变压器容量" value={planStore.transformerCapacity} suffix={"kVA"}/>
                        </Col>
                    </Row>
                </div>

                {
                    planStore.prices?.map((price, index) => (<>
                        <br/>
                        <DividerTitle
                            title={`电价【${index + 1}】`}
                            midContent={<MonthSelectorOnlyShow index={index} disable/>}
                        ></DividerTitle>
                        <ElectrovalenceChart
                            showTitle={false}
                            key={`electeoncalenceChart${index}`}
                            elecIndex={index}
                            prices={price}/>
                    </>))
                }
                <div className="br-8" />
                <DividerTitle title={'历史负荷和模拟运行数据'}></DividerTitle>
                <LoadCurveChart loadCurveData={planStore.loadData}
                                pcsPowers={planStore.pcsPowers}
                                socs={planStore.socs}
                                showTitle={false}
                />
                <div className="br-8" />
                <div className="reporter-footer">
                    <div className="qrcode-container">
                        <img src={QrcodeImage} alt=""/>
                        <div className="qrcode-content">关注则鸣</div>
                    </div>
                    <div className="logo-container">
                        <img src={StdLogoImage} alt=""/>
                        <div className="logo-content">
                            <span>访问<a href="https://www.zenergytech.cn/">https://www.zenergytech.cn/</a></span>
                            <span>了解更多行业资讯&则鸣详情、解决方案</span>
                        </div>
                    </div>
                </div>
                <Divider/>
                <Typography>
                    <Title>声明</Title>

                    <Paragraph>
                        这份报告由则鸣工商业储能规划分析工具( <Link
                        href="https://plan.zenergytech.cn/">https://plan.zenergytech.cn/</Link>)自动生成。
                    </Paragraph>

                    <Paragraph>
                        该工具是由则鸣新能源技术有限公司开发的在线应用程序，<Text strong>
                        目的是为工商业储能电站的容量规划和收益测算提供参考依据。
                    </Text>
                    </Paragraph>

                    <Paragraph>
                        本工具仅供一般参考之用。则鸣新能源无法对数据的准确性和完整性做出承诺。
                    </Paragraph>

                    <Paragraph>
                        建议本工具的使用仅限于为有关项目的可行性讨论提供参考，以便相关人员对项目的建设和使用效果进行更完整的评价。
                    </Paragraph>
                    <Paragraph>
                        则鸣新能源已尽最大努力根据现有数据、算法和知识对工商业储能系统的运行表现进行评估， 但则鸣无法对本报告用于财务承诺或类似场合而造成的损失负责。
                    </Paragraph>

                    <Paragraph>
                        <Text strong>版权所有:</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text strong>南京则鸣新能源技术有限公司 𝕔 2024</Text>
                    </Paragraph>

                </Typography>
            </Watermark>
        </div>
    );
}

export default observer(ViewResults);
