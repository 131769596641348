import DividerTitle from "../../divider-title";
import {InputNumber, Radio, Switch, Tooltip} from "antd";
import {useState} from "react";

import './style.scss'
import {observer} from "mobx-react";
import {planStore} from "../../../store";
import {InfoCircleOutlined} from "@ant-design/icons";

const pricingTierSufix = {
    3: '高峰', // peak
    2: '低谷', // offPeak
    1: '平段', // normal
    4: '尖峰', // deepPeak
    5: '深谷' // deepOffPeak
};
// 至少有两个峰段，且这两个峰段前都存在谷段才可以选择两充两放。
function peakTimePeriodStatistics(prices) {
    return prices.every(price => {
        // 过滤出所有峰值时刻的记录
        const peakTimes = price.filter(item => item.type === 3 || item.type === 4);

        // 检查峰值时刻是否存在至少两个不连续的时间段
        if (peakTimes.length < 2) {
            // 如果峰值时刻少于两个，不可能存在两个不连续的时间段
            return false;
        }

        // 对峰值时刻按小时进行排序
        peakTimes.sort((a, b) => a.hour - b.hour);

        // 定义变量来跟踪不连续的峰值时段的数量
        let discontinuousPeriods = 1;  // 从第一个时段开始
        let discontinuityStart = 0; // 记录第一个不连续点的开始
        for (let i = 1; i < peakTimes.length; i++) {
            // 如果当前峰值时刻与前一个峰值时刻不连续
            if (peakTimes[i].hour !== peakTimes[i - 1].hour + 1) {
                discontinuousPeriods++;
                if (discontinuousPeriods === 2) {
                    discontinuityStart = i;
                    break;
                }
            }
        }

        if (discontinuousPeriods === 2) {
            // 检查不连续的峰值时段之间是否有谷时
            const startHour = peakTimes[discontinuityStart - 1].hour;
            const endHour = peakTimes[discontinuityStart].hour;

            // 检查这个区间内是否存在类型为低谷或深谷的时段
            return price.some(item =>
                (item.type === 2 || item.type === 5) &&
                item.hour > startHour && item.hour < endHour
            );
        }

        return false;
    });
}


function ThirdStep() {

    return (
        <div className="third-step main-content-width">
            <DividerTitle title={'变压器配置'}></DividerTitle>
            <div className="third-step__item">
                <span className="third-step__item__label">变压器容量
                    <Tooltip title="或电网合同的最大需量">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>：</span>
                <InputNumber
                    onChange={(value) => planStore.setTransformerCapacity(value)}
                    min={0}
                    value={planStore.transformerCapacity===0?'':planStore.transformerCapacity}
                />
                <span className="label2">kVA</span>
            </div>
            <DividerTitle title={'储能运行模式'}></DividerTitle>
            <div className="third-step__item">
                <span className="third-step__item__label">防逆流
                    <Tooltip title="则鸣EMS及部分友商的EMS支持防逆流功能，开启后可以避免储放电时负荷轻载导致电能返送至电网，该功能可防止电能浪费，降低设备寿命衰减速度">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>：</span>
                <Switch checkedChildren="是" unCheckedChildren="否" defaultChecked={
                    planStore.antiReflux === 1
                } onChange={(checked) => planStore.setAntiReflux(checked ? 1 : 0)
                }/>
            </div>
            <div className="third-step__item">
                <span className="third-step__item__label">防过载<Tooltip title="则鸣EMS及部分友商的EMS支持防过载功能，开启后可以避免夜间负荷较大的业主在储能充电时出现变压器过载现象，避免因安装储能系统引入安全风险。另外需量管理也可通过该功能配合变压器容量参数的合理设置来实现">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>：</span>
                <Switch checkedChildren="是" unCheckedChildren="否" defaultChecked={
                    planStore.antiOverload === 1
                } onChange={(checked) => planStore.setAntiOverload(checked ? 1 : 0)
                }/>
            </div>
            {/*<DividerTitle title={'运行模式'}></DividerTitle>*/}
            <div className="third-step__item">
                <span className="third-step__item__label">充放电次数<Tooltip title="所在地电价的多个峰段中间具有谷段或平段时可以启用“两充两放模式”，启用后储能容量可以显著降低，但日均充放次数会上升">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>：</span>
                <Radio.Group onChange={(e) => planStore.setMode(
                    e.target.value
                )} value={planStore.mode}>
                    <Radio value={0}>一充一放</Radio>
                    {!peakTimePeriodStatistics(planStore.prices) &&
                        <Tooltip title="至少有两个峰段，且这两个峰段前都存在谷段才可以选择两充两放。">
                            <Radio value={1} disabled>两充两放</Radio>
                        </Tooltip>}
                    {peakTimePeriodStatistics(planStore.prices) && <Radio value={1}>两充两放</Radio>}
                </Radio.Group>
            </div>
            <div className="third-step__item">
                <span className="third-step__item__label">平段充电：</span>
                <Radio.Group onChange={(e) => planStore.setFlatCharging(
                    e.target.value
                )} value={planStore.flatCharging}>
                    <Tooltip title="即将支持对“平段充电”模式的收益分析，敬请期待。">
                        <Radio value={1} disabled>允 许</Radio>
                    </Tooltip>
                    <Radio value={0}>不允许</Radio>
                </Radio.Group>
            </div>
        </div>
    );
}

export default observer(ThirdStep);
