import ReactECharts from 'echarts-for-react';

function ElectrovalenceChart({prices, elecIndex, showTitle = true}) {

    const getOption = () => ({
        title: {
            text: showTitle ? `电价曲线${elecIndex !== undefined ? `【${elecIndex + 1}】`:''}` : '',
            left: 'left',
            top: 0
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '0%',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: Array.from({length: 24}, (_, i) => `${i}:00`),
            boundaryGap: true,  // 使条形与标签对齐
            axisLabel: {
                align: 'center',
                interval: 0
            }
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 'dataMax'
        },
        series: [{
            // data: prices.map(price => price.price),
            data: Array.from({length: 24}, (_, i) => prices?.find(price => price.hour === i)?.price || 0),
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.01)'
            },
            itemStyle: {
                color: 'rgba(24, 144, 255, 1)'
            }
        }]
    });

    return <ReactECharts option={getOption()} style={{height: 200}}/>;
}

export default ElectrovalenceChart;
