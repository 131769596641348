import DividerTitle from "../../divider-title";
import {Slider, Tooltip} from "antd";

import './style.scss'
import UploadLoad from "./upload";
import {observer} from "mobx-react";
import {planStore} from "../../../store";
import {InfoCircleOutlined} from "@ant-design/icons";
import LoadEditorSelector from "./LoadEditorSelector";

const formatter1 = (value) => `${value}%`;
const marks1 = {
    0: '0%',
    50: '50%',
    100: '100%',
    150: '150%',
    200: '200%',
};

const formatter2 = (value) => `${parseInt(`${value}`)}天`;
const marks2 = {
    200: '200天',
    250: '250天',
    300: '300天',
    330: '330天',
    350: '350天',
};

function SecondStep({form}) {
    return (
        <div className="second-step main-content-width">
            <div>
                <DividerTitle title={'历史负荷数据'}
                            onRefresh={() => planStore.clearLoadData()}
                            refreshVisible={planStore.loadCurveId !== null}
                            //   midContent={planStore.loadCurveId == null && <LoadEditorSelector />}
                ></DividerTitle>
            </div>
            <div>
                {planStore.loadCurveId == null && <LoadEditorSelector />}
            </div>
            <div>
                <UploadLoad/>
            </div>
            <DividerTitle title={'用电负荷调整'}></DividerTitle>
            <div className="second-step__slider">
                <span className="second-step__slider-label">未来负荷增长
                     <Tooltip title="预计未来用电量和导入历史用电量的比例关系">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                    ：</span>
                <Slider marks={marks1} defaultValue={planStore.adjustingPowerConsumption} min={0} max={200}
                        tooltip={{formatter: formatter1}} step={1}
                        onChange={
                            (value) => {
                                planStore.setAdjustingPowerConsumption(value);
                            }
                        }
                />
            </div>

            <div className="second-step__slider">
                <span className="second-step__slider-label">满充满放天数
                     <Tooltip title="为了避免节假日等时段储能资产闲置，此处设置的天数越大则可安装的储能系统容量越小">
                       <InfoCircleOutlined style={{margin: '0px 4px'}}/>
                </Tooltip>
                    ：</span>
                <Slider marks={marks2} defaultValue={planStore.effectiveDays} tooltip={{formatter: formatter2}} step={1}
                        min={200} max={350}
                        onChange={
                            (value) => {
                                planStore.setEffectiveDays(value);
                            }}
                />
            </div>
        </div>
    );
}

export default observer(SecondStep);
