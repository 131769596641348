import DividerTitle from "../../divider-title";
import {Button, Tooltip} from "antd";
import {InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import ElecPrice from "./elec-price";
import ElectrovalenceChart from "./electrovalence-chart";
import {planStore} from "../../../store";
import {observer} from "mobx-react";
import MonthSelector from "./MonthSelector";

/**
 * 电价信息区块
 * @param props {
 *     index: number, // 电价索引
 * }
 * @return {JSX.Element}
 * @constructor
 */
function ElecPriceBlock(props) {
    const {index} = props;
    return (
        <>
            <DividerTitle title={`电价信息(${index + 1})`}
                          midContent={<MonthSelector index={index}/>}
                          rightContent={<>
                              {index === 0 && <Button type="primary"
                                                      shape="round"
                                                      size="small"
                                                      disabled={planStore.structuredPrices?.length > 1}
                                                      style={{
                                                          fontSize: 12,
                                                          fontWeight: 500,
                                                      }}
                                                      icon={<PlusCircleOutlined/>}
                                                      onClick={() => planStore.addStructuredPrices()}
                              >添加电价</Button>}
                              {index > 0 && <Button danger
                                                    shape="round"
                                                    size="small"
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                    icon={<MinusCircleOutlined/>}
                                                    onClick={() => {
                                                        // 清理已经存在的电价id
                                                        planStore.setPriceTemplateIds(planStore.priceTemplateIds?.filter((_, i) => i !== index));
                                                        planStore.setPrices(planStore.prices?.filter((item, i) => i !== index));
                                                        planStore.removeStructuredPrices(index);
                                                        planStore.setElecPriceMonths(planStore?.elecPriceMonths.map(item => item === index ? 0 : item));
                                                        planStore.setInitStructuredPrices(planStore?.initStructuredPrices.filter((item, i) => i !== index), {
                                                            syncStructuredPrices: false,
                                                            replace: true,
                                                        });
                                                    }}
                              >删除电价</Button>}
                          </>}
            >
                <Tooltip title={
                    <p>
                        <p>1. 当某个电价的时段是9:00~11:00时，表示从9点开始到11点（包含11点）的电价为设定值</p>
                        <p>2. 每种电价可以出现在多个不连续的时段，点击时段后面的“+”添加时段</p>
                        <p>3. 如果有带尖峰或低谷的第二套电价，需要在电价标题旁的下拉框选择对应月份。</p>
                    </p>
                }>
                    <InfoCircleOutlined style={{width: 18, height: 18}}/>
                </Tooltip>
            </DividerTitle>
            <br/>
            <ElecPrice type={4} elecIndex={index} cusAttribute="deepPeak" name='尖峰'/>
            <ElecPrice type={3} elecIndex={index} cusAttribute="peak" name="高峰"/>
            <ElecPrice type={1} elecIndex={index} cusAttribute="normal" name="平段"/>
            <ElecPrice type={2} elecIndex={index} cusAttribute="offPeak" name="低谷"/>
            <ElecPrice type={5} elecIndex={index} cusAttribute="deepOffPeak" name="深谷"/>

            <ElectrovalenceChart prices={planStore.prices[index]}/>
        </>
    );
}

export default observer(ElecPriceBlock);
