import {Button, message, Popconfirm, Modal, Form, Input, InputNumber} from "antd";
import './style.scss'
import {useCallback, useState} from "react";
import {observer} from "mobx-react";
import {planStore} from "../../store";
import {
    addStation,
    bindingStation, logOperate,
    queryResultByStationId,
    saveElecPriceData,
    settlement,
    updateStation,
    customCompute
} from "../../services/api";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import {ExclamationCircleOutlined} from "@ant-design/icons";

function isCompletePriceData(prices) {
    // 创建一个包含0到23的所有小时的数组
    const allHours = Array.from({length: 24}, (_, index) => index);

    // 检查每个小时是否在prices中有对应的电价数据
    for (let hour of allHours) {
        if (!prices.some(price => price.hour === hour)) {
            return false; // 如果找不到对应的小时，则返回false
        }
    }
    return true; // 如果所有小时都有对应的电价数据，则返回true
}


const printDocument = () => {
    const element = document.getElementById('divToPrint');

    // 设置 html2canvas 的选项
    const options = {
        scale: 3, // 将比例设置为 3,以提高生成图像的分辨率
        useCORS: true, // 如果你的页面使用了跨域资源,需要设置为 true
        allowTaint: true, // 允许跨域图像
        logging: true, // 启用日志记录
        letterRendering: true, // 提高字体渲染质量
    };

    html2canvas(element, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // 根据 canvas 的宽度和高度计算 PDF 的尺寸
        const pdfWidth = canvas.width / 4; // 将宽度缩小为原来的 1/4
        const pdfHeight = canvas.height / 4; // 将高度缩小为原来的 1/4

        const pdf = new jspdf({
            orientation: "p",
            unit: "px",
            format: [pdfWidth, pdfHeight], // 使用计算得到的 PDF 尺寸
            compress: true, // 启用压缩以减小文件大小
        });

        // 定义边距
        const margin = 20;
        const width = pdfWidth - 2 * margin;
        const height = pdfHeight - 2 * margin;

        pdf.addImage(imgData, 'PNG', margin, margin, width, height);
        pdf.save('zeming.pdf');
    });
};

function BottomControl({
                           form,
                           formData,
                           setFormData,
                       }) {

    const nextStep = useCallback(() => {
        if (planStore.currentStep === 0) {
                logOperate({
                    traceId: planStore.fingerprintId,
                    type: 'input',
                }).then(
                    res => {
                        console.log('logOperate', res);
                    }).catch(
                    err => {
                        console.error(err);
                    });

                if(form.getFieldsValue().location===undefined || form.getFieldsValue().location.length===0 || 
                    form.getFieldsValue().name === undefined || form.getFieldsValue().name.length===0){
                    message.error('基本信息不完整，请检查后提交');
                    return;
                }else{
                    planStore.setSelectedCity(form.getFieldValue(['location']));
                    planStore.setProjectName(form.getFieldValue(['name']));
                }
        
                if (!planStore.prices.every(price => isCompletePriceData(price))) {
                    message.error('电价数据不完整，请检查后提交');
                    return;
                }
                    // else if (new Array(planStore.prices.length).every(
                    //     (_, index) => {
                    //         const a = planStore.elecPriceMonths.filter(elecIndex => elecIndex === index);
                    //         console.log('a', a);
                    //         return a.length === 0
                    //     }
                    // )) {
                    //     message.error('电价适用月份不完整，请检查后提交');
                    //     return;
                // }
                else {
                    planStore.prices.forEach((prices, index) => {
                        if (!planStore.hasPriceTemplateId(index)) {
                            // 不存在模版id，保存电价数据，生成新的模板id
                            saveElecPriceData(
                                {
                                    province: planStore.selectedCity[0],
                                    city: planStore.selectedCity[1],
                                    data: prices.map(
                                        price => ({
                                            ...price,
                                        })
                                    ),
                                    months: planStore.elecPriceMonths
                                        .map((_, i) => _ === index ? i + 1 : false)
                                        .filter(Boolean)
                                        .join(','),
                                }
                            ).then(
                                res => {
                                    console.log('保存电价数据,', res);
                                    // 电价模版id
                                    const priceTemplateIds = res.data?.data;
                                    planStore.setPriceTemplateIds(priceTemplateIds, index);
                                }
                            ).catch(
                                err =>
                                    console.error(err)
                            )
                        }
                    });
                }
            }
        if (planStore.currentStep === 1) {
            if (!planStore.hasLoadCurveId()) {
                message.error('请录入负荷数据，您可以使用模板数据生成或者上传自己的历史负荷数据');
                return;
            }
            // todo 判断参数
        }
        if (planStore.currentStep === 2) {
            if (planStore.transformerCapacity===0) {
                message.error('请输入变压器容量');
                return;
            }
        }

        if (planStore.currentStep === 3) {
            // todo 判断参数
        }
        planStore.setCurrentStep(planStore.currentStep + 1);
    }, [form, formData, setFormData, planStore.currentStep, planStore.setCurrentStep, planStore.elecPriceMonths,]);

    const prevStep = useCallback(() => {
        const values = form.getFieldsValue();
        setFormData({...formData, ...values});
        planStore.setCurrentStep(planStore.currentStep - 1);
    }, [form, formData, setFormData, planStore.currentStep, planStore.setCurrentStep]);


    const submit = () => {
        planStore.setSubmitModalVisible(true);
        changeStationData().then(
            res => {
                console.log('addStation', res);
                if (res.data.code === 0) {
                    const id = res.data?.data?.id;
                    if (id) {
                        // 更新url中添加stationId参数
                        window.history.pushState({}, '', `?stationId=${id}`);
                        settlement(id).then(
                            res => {
                                console.log('settlement', res);
                                if (res.data.code === 0) {
                                    const intervalId = setInterval(
                                        () => {
                                            queryResultByStationId(id).then(
                                                res => {
                                                    console.log('queryResultByStationId', res);
                                                    if (res.data.code === 0) {
                                                        clearInterval(intervalId);
                                                        const data = res.data?.data;
                                                        if (data) {
                                                            planStore.setStationResult(data);
                                                            planStore.setSubmitModalVisible(false);
                                                            planStore.setCurrentStep(4);
                                                            try {
                                                                const pcsPowers = JSON.parse(data.pcsPowers);
                                                                const socs = JSON.parse(data.socs);
                                                                const loads = data.loads;

                                                                planStore.setLoadData(loads);
                                                                planStore.setPcsPowers(pcsPowers);
                                                                planStore.setSocs(socs);
                                                            } catch (e) {
                                                                console.error(e);
                                                            }
                                                            planStore.setIsCustomCompute(false);
                                                        }
                                                    }
                                                }
                                            ).catch(
                                                err => {
                                                    console.error(err);
                                                }
                                            );
                                        }, 1000)
                                } else {
                                    planStore.setSubmitModalVisible(false);
                                    message.error(`提交失败，错误信息：${res.data.msg}`);
                                }
                            }
                        ).catch(
                            err => {
                                console.error(err);
                            }
                        );
                    }
                    // setTimeout(
                    //     () => {
                    //         planStore.setSubmitModalVisible(false);
                    //         message.success('提交成功');
                    //     }, 1000
                    // )
                } else {
                    planStore.setSubmitModalVisible(false);
                    message.error('提交失败');
                }
            }
        ).catch(
            err => {
                planStore.setSubmitModalVisible(false);
                message.error('提交失败');
                console.error(err);
            }
        );
    };

    const changeStationData = () => {
        // 判断url是否包含stationId参数
        const url = new URL(window.location.href);
        const stationId = url.searchParams.get('stationId');
        if (!stationId) {
            return addStation({
                "name": planStore.name,
                "province": planStore.selectedCity[0],
                "city": planStore.selectedCity[1],
                "elecPriceTemplateIds": planStore.priceTemplateIds.join(','),
                "loadCurveId": planStore.loadCurveId,
                "adjustingPowerConsumption": planStore.adjustingPowerConsumption / 100,
                "effectiveDays": planStore.effectiveDays,
                "transformerCapacity": planStore.transformerCapacity,
                "antiReflux": planStore.antiReflux,
                "antiOverload": planStore.antiOverload,
                "mode": planStore.mode,
                // 置信度暂时去除
                // "confidence": ,
                "flatCharging": planStore.flatCharging,
                "systemTileCost": planStore.systemTileCost,
                "fillChargeEffic": planStore.fillChargeEffic / 100,
                "pcsEffic": planStore.pcsEffic / 100,
                "otherEffic": planStore.otherEffic / 100,
                "dischargeableDepth": planStore.dischargeableDepth / 100,
                "selfPowerConsumption": planStore.selfPowerConsumption,
                "traceId": planStore.fingerprintId,
            })
        } else {
            return updateStation({
                "id": stationId,
                "name": planStore.name,
                "province": planStore.selectedCity[0],
                "city": planStore.selectedCity[1],
                "elecPriceTemplateIds": planStore.priceTemplateIds.join(','),
                "loadCurveId": planStore.loadCurveId,
                "adjustingPowerConsumption": planStore.adjustingPowerConsumption / 100,
                "effectiveDays": planStore.effectiveDays,
                "transformerCapacity": planStore.transformerCapacity,
                "antiReflux": planStore.antiReflux,
                "antiOverload": planStore.antiOverload,
                "mode": planStore.mode,
                // 置信度暂时去除
                // "confidence": ,
                "flatCharging": planStore.flatCharging,
                "systemTileCost": planStore.systemTileCost,
                "fillChargeEffic": planStore.fillChargeEffic / 100,
                "pcsEffic": planStore.pcsEffic / 100,
                "otherEffic": planStore.otherEffic / 100,
                "dischargeableDepth": planStore.dischargeableDepth / 100,
                "selfPowerConsumption": planStore.selfPowerConsumption,
                "traceId": planStore.fingerprintId,
            })
        }
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeBatteryCapacityForm] = Form.useForm();

    const showModal = () => {
      setIsModalVisible(true);
      changeBatteryCapacityForm.resetFields();
    };
  
    const handleOk = async () => {
      try {
        const values = await changeBatteryCapacityForm.validateFields();
        console.log("form data",values);
        if(values.pcsEffic > values.batteryCapacity/2){
            return message.warning("建议PCS功率不大于电池容量的二分之一");
        }
        setLoading(true);
        await customCompute(planStore.stationResult.stationId, values).then((res)=>{
            console.log('customCompute',res);
            const data = res.data?.data;
            if (data) {
                planStore.setStationResult(data);
                planStore.setCurrentStep(4);
                try {
                    const pcsPowers = JSON.parse(data.pcsPowers);
                    const socs = JSON.parse(data.socs);

                    planStore.setPcsPowers(pcsPowers);
                    planStore.setSocs(socs);
                } catch (e) {
                    console.error(e);
                }
            }
            planStore.setIsCustomCompute(true);
        }).catch((err)=>{
            console.log('error',err);
            message.error("请求失败，请重试");
        });
        setIsModalVisible(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
      setLoading(false);
    };

    return (
        <div className="bottom-control main-content-width">
            <div>
                {planStore.currentStep > 0 ?
                    <Button className="bottom-control__botton" style={{marginRight: 10}}
                            onClick={prevStep}>上一步</Button> :
                    null}
                {planStore.currentStep > 3 ?
                    <Popconfirm
                        title={"重置项目"}
                        description={"是否确认重置项目？"}
                        okText={"确认"}
                        cancelText={"取消"}
                        icon={<ExclamationCircleOutlined/>}
                        onConfirm={() => {
                           // 去除所有参数刷新当前页面链接
                            window.history.pushState({}, '', '/');
                            window.location.reload();
                        }}
                    >
                        <Button danger className="bottom-control__botton" style={{marginRight: 10}}>重置</Button>
                    </Popconfirm>
                    :
                    null
                    }
                {planStore.currentStep > 3 ? 
                    <Button type="primary" className="bottom-control__botton" onClick={showModal}>修改容量</Button>
                :null}
                <Modal
                    title="自定义电池容量和PCS功率进行测算"
                    open={isModalVisible}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                            提交
                        </Button>
                    ]}
                >
                    <Form form={changeBatteryCapacityForm} layout="vertical">
                    <Form.Item
                        label="电池容量（KWH）"
                        name="batteryCapacity"
                        rules={[{ required: true, message: '请输入电池容量' }]}
                    >
                        <InputNumber min={0.1} max={99999999} style={{ width: '100%' }} step={0.1}/>
                    </Form.Item>

                    <Form.Item
                        label="PCS功率（KW）"
                        name="pcsEffic"
                        rules={[{ required: true, message: '请输入PCS功率' }]}
                    >
                        <InputNumber min={0.1} max={99999999} style={{ width: '100%' }} step={0.1}/>
                    </Form.Item>
                    </Form>
                </Modal>  
            </div>

            {planStore.currentStep < 4 && <div className="bottom-control__botton—group">
                {planStore.currentStep < 3 ?
                    <Button className="bottom-control__botton" type="primary" onClick={nextStep}>下一步</Button> :
                    <span></span>}
            </div>}
            {planStore.currentStep === 3 && <div className="bottom-control__botton—group">
                <Button className="bottom-control__botton" onClick={submit}>提交</Button>
            </div>}
            {planStore.currentStep === 4 && <div className="bottom-control__botton—group">

                <Button className="bottom-control__botton" type="primary" onClick={() => {
                    if (!planStore.isLogin) {
                        planStore.setLoginModalVisible(true);
                        return
                    }

                    // 通过url获取到stationId
                    const url = new URL(window.location.href);
                    const stationId = url.searchParams.get('stationId');
                    if (stationId) {
                        bindingStation(stationId).then(
                            res => {
                                console.log('bindingStation', res);
                                if (res.data.code === 0) {
                                    message.success('保存成功');
                                    setTimeout(
                                        () => {
                                            // 跳转管理页
                                            window.location.href = '/projects';
                                        }, 1000
                                    )
                                } else {
                                    message.error('保存失败');
                                }
                            }
                        ).catch(
                            err => {
                                console.error(err);
                            }
                        );
                    }
                }}>保存项目</Button>


                <Button className="bottom-control__botton" onClick={() => {
                    printDocument();
                }}>下载为PDF</Button>
            </div>}
        </div>
    );
}

export default observer(BottomControl);
