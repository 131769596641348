import React from "react";

import './style.scss';

function ResultTag({
                       tag,
                       value,
                       unit,
                   }) {
    return <div className="result-tag-container">
        <div className="result-tag-container__tag">{tag}</div>
        <div className="result-tag-container__right">
            <div className="result-tag-container__value">{value}</div>
            <div className="result-tag-container__unit">{unit}</div>
        </div>

    </div>
}

export default ResultTag;
