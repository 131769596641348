export function PersonalIcon() {
    return (
        <svg className="icon" viewBox="0 0 1024 1024" width="100%" height="100%">
            <path
                d="M719.5 552.9c-31.4-20.6-64.7-36.9-99.6-48.7 21.4-11 41.2-25.3 58.8-42.8 44-44 68.2-102.5 68.2-164.7S722.6 176 678.6 132c-44-44-102.5-68.2-164.7-68.2S393.3 88.1 349.3 132c-44 44-68.2 102.5-68.2 164.7s24.2 120.7 68.2 164.7c15.8 15.8 33.5 29 52.5 39.5-21.4 5.8-42.3 13.3-62.8 22.4-53.4 23.8-101.3 57.9-142.4 101.4-41.1 43.4-73.4 94-96 150.4C80.2 826 68.5 879.7 65.9 935c-0.6 13.2 9.2 24.2 21.7 24.2h0.1c11.6 0 21.1-9.7 21.7-21.9C120.3 712 297.3 532.1 513.3 532.1h0.6v-2.6c1.8 0 3.5 0 5.3-0.1v0.1c61.2 5.7 122 26.2 177.2 62.4 9.9 6.5 22.8 3.7 29.5-6.3 7.2-10.8 4.2-25.7-6.4-32.7zM324.7 296.7c0-104.4 84.9-189.3 189.3-189.3s189.3 84.9 189.3 189.3S618.3 486 514 486c-104.4 0-189.3-84.9-189.3-189.3z"
                fill="#ffffff"></path>
            <path
                d="M941 695.1H741.5c-10.3 0-18.6-8.4-18.6-18.6 0-10.3 8.4-18.6 18.6-18.6H941c10.3 0 18.6 8.4 18.6 18.6 0 10.3-8.4 18.6-18.6 18.6zM941 959.2H741.5c-10.3 0-18.6-8.4-18.6-18.6 0-10.3 8.4-18.6 18.6-18.6H941c10.3 0 18.6 8.4 18.6 18.6 0 10.2-8.4 18.6-18.6 18.6zM941 871.2H741.5c-10.3 0-18.6-8.4-18.6-18.6 0-10.3 8.4-18.6 18.6-18.6H941c10.3 0 18.6 8.4 18.6 18.6 0 10.2-8.4 18.6-18.6 18.6zM941 783.1H741.5c-10.3 0-18.6-8.4-18.6-18.6 0-10.3 8.4-18.6 18.6-18.6H941c10.3 0 18.6 8.4 18.6 18.6 0 10.3-8.4 18.6-18.6 18.6z"
                fill="#ffffff"></path>
        </svg>
    );
}
