import {Modal, Input, Button, Form, message, Col, Row} from 'antd';
import {MobileOutlined, LockOutlined} from '@ant-design/icons';
import {useCallback, useEffect, useState} from "react";
import Captcha from 'react-captcha-code';

import Logo from '../../assets/images/logo.jpg';

import './style.scss';
import {loginWithVerificationCode, sendVerificationCode} from "../../services/api";
import {planStore} from "../../store";

const countDown = 120;

function LoginModal({isVisible, onClose}) {
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [form] = Form.useForm();

    const handleChangeCaptcha = (captcha) => {
        setCaptcha(captcha);
    };

    // 倒计时120s
    // 发送验证码按钮是否可用
    const [sendCodeDisabled, setSendCodeDisabled] = useState(false);

    const [currentCountDown, setCurrentCountDown] = useState(0);

    // 启动倒计时，倒计时结束恢复按钮状态
    const handleCountDown = () => {
        setCurrentCountDown(countDown);
        setSendCodeDisabled(true);
        const timer = setInterval(() => {
            setCurrentCountDown(count => {
                if (count <= 0) {
                    clearInterval(timer);
                    setSendCodeDisabled(false);
                    return 0;
                }
                return count - 1;
            });
        }, 1000);
    }

    const handleSendCode = () => {
        // 检查图形验证码是否已输入
        form.validateFields(['phone', 'captcha']).then(values => {
            // 模拟校验图形验证码
            if (values.captcha !== captcha) {
                message.error('图形验证码错误，请重新输入！');
                setCaptchaInput(''); // 清空图形验证码输入框
            } else {
                // 发送验证码
                sendVerificationCode({
                    phone: values.phone,
                }).then((res) => {
                    console.log('sendVerificationCode', res)
                    if (res?.data.code === 0) {
                        message.success(`验证码已发送至 ${values.phone}，请查收！`);
                        handleCountDown();
                    } else {
                        message.error('验证码发送失败！请稍后重试！');
                    }
                });
            }
        }).catch(errorInfo => {
            message.error('信息错误！');
        });
    };

    const handleSubmit = (values) => {
        setLoading(true);
        // 登录过程
        loginWithVerificationCode({
            account: values.phone,
            captcha: values.code,
        }).then(res => {
            console.log('loginWithVerificationCode', res);
            if (res?.data.code === 0) {
                message.success('登录成功！');
                planStore.setIsLogin(true);
                planStore.setProfile(res.data.data);
                onClose();
            } else {
                message.error('登录失败！');
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Modal
            title=""
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            className="login-modal"
            width={372}
            maskClosable={false}
            centered
        >
            <div className="login-modal_title">
                <img src={Logo} alt="" className="login-modal_title__logo"/>
                <span className="login-modal_title__text">手机号登录</span>
                <div className="login-modal_title__line"/>
            </div>
            <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                    name="phone"
                    rules={[{required: true, message: '请输入手机号码'}]}
                >
                    <Input prefix={<MobileOutlined/>} placeholder="手机号"/>
                </Form.Item>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            name="captcha"
                            rules={[{required: true, message: '请输入图形验证码'}]}
                        >
                            <Input
                                prefix={<LockOutlined/>}
                                placeholder="图形验证码"
                                value={captchaInput}
                                onChange={(e) => setCaptchaInput(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Captcha
                            charNum={4}
                            onChange={handleChangeCaptcha}
                            restart={true}
                        />
                    </Col>
                </Row>
                <Form.Item
                    name="code"
                    rules={[{required: true, message: '请输入验证码'}]}
                >
                    <Input
                        prefix={<LockOutlined/>}
                        suffix={
                            <>
                                {currentCountDown > 0 && <span className="count-down">{currentCountDown}s</span>}
                                <Button onClick={handleSendCode} size="small" disabled={sendCodeDisabled}>
                                    发送验证码
                                </Button>
                            </>

                        }
                        placeholder="验证码"
                    />
                </Form.Item>
                <Form.Item>
                    <span>登录即代表您已同意</span>
                    <a href="">《用户协议》</a>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LoginModal;
