import React, {useMemo} from "react";
import {TimePicker, InputNumber, Button, message} from "antd";
import './elec-price.scss'
import {MinusCircleFilled, PlusCircleFilled} from "@ant-design/icons";
import {useCallback, useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {observer} from "mobx-react";
import {planStore} from "../../../store";
import moment from "moment";
import {toJS} from "mobx";
import {useMobxValue} from "../../../store/hooks";

const format = 'HH:mm';

const pricingTierSufix = {
    3: '高峰', // peak
    2: '低谷',  //offPeak
    1: '平段', // normal
    4: '尖峰', // deepPeak
    5: '深谷', // deepOffPeak
}

const disabledMinutes = () => {
    return Array.from({length: 60}, (_, index) => index).filter(minute => minute !== 0);
};

function ElecPrice({
                       // 5深谷 4尖峰 峰3 谷2 平1
                       type,
                       // 结构化数据属性
                       cusAttribute,
                       setPrices = planStore.setPrices.bind(planStore),
                       // 电价索引，代表当前是第几个电价
                       elecIndex,
                   }) {

    const initStructuredPrices = useMobxValue(() => planStore.initStructuredPrices);
    const structuredPrices = useMobxValue(() => planStore.structuredPrices);

    const prices = planStore.prices?.[elecIndex] || [];

    /**
     * 结构化数据
     * @type {{
     * hours:number[][],
     * price:number,
     * type:number,
     * }}
     */
    const structuredPrice = structuredPrices?.[elecIndex]?.[cusAttribute] || {};

    // 时段选择
    const [timePeriods, setTimePeriods] = useState([{
        key: uuidv4(),
        start: null,
        end: null,
    }]);

    const [price, setPrice] = useState(0);

    useEffect(() => {
        setPrice(structuredPrice?.price || 0);
        setTimePeriods(structuredPrice?.hours?.reduce((acc, hours) => {
            // Check if the hours array spans over midnight
            const splitIndex = hours.findIndex((hour, index, arr) => index > 0 && arr[index - 1] > hour);
            if (splitIndex === -1) {
                // No split needed, push the whole range
                acc.push({
                    key: uuidv4(),
                    start: moment(hours[0], format),
                    end: moment(hours[hours.length - 1], format),
                });
            } else {
                // Split needed, create two periods
                const firstPeriod = hours.slice(0, splitIndex);
                const secondPeriod = hours.slice(splitIndex);
                acc.push({
                    key: uuidv4(),
                    start: moment(firstPeriod[0], format),
                    end: moment(firstPeriod[firstPeriod.length - 1], format),
                });
                acc.push({
                    key: uuidv4(),
                    start: moment(secondPeriod[0], format),
                    end: moment(secondPeriod[secondPeriod.length - 1], format),
                });
            }
            return acc;
        }, []) || [{
            key: uuidv4(),
            start: null,
            end: null,
        }]);
    }, [JSON.stringify(initStructuredPrices)]);


    const addTimePeriod = () => {
        setTimePeriods([...timePeriods, {
            key: uuidv4(),
            start: null,
            end: null
        }]);
    };

    const removeTimePeriod = key => {
        setTimePeriods(timePeriods.filter(period => period.key !== key));
    };

    useEffect(() => {
        if (timePeriods.filter((i) => i.start !== null).length === 0) return;
        console.log('timePeriods', timePeriods);
        // 转换成prices类型同步到prices上去
        const departPrices = []
        timePeriods.forEach((period) => {
            const start = period.start ? period.start.format(format).split(':')[0] : null;
            const end = period.end ? period.end.format(format).split(':')[0] : null;
            console.log('start', start, 'end', end, 'price', price, type);
            if (start && end) {
                // 从数字start到数字end遍历
                for (let i = parseInt(start); i <= parseInt(end); i++) {
                    departPrices.push({
                        hour: i,
                        price: price,
                        type: type,
                    })
                }
            }
        })
        // 当前类型的数据全部清除
        const newPrices = prices.filter((price) => {
            return price.type !== type;
        })
        setPrices([...newPrices, ...departPrices], elecIndex);

        if (timePeriods.filter((i) => i.start === null || i.end === null).length > 0) return;
        planStore.setStructuredPrices(cusAttribute, {
            hours: timePeriods.map(period => {
                return [period.start.hour(), period.end.hour()]
            }),
            price: price,
            type: type,
        }, elecIndex);
    }, [timePeriods, price, type]);

    const checkOverlap = (periods, key) => {
        const sortedPeriods = periods.sort((a, b) => a.start - b.start);
        for (let i = 0; i < sortedPeriods.length - 1; i++) {
            if (sortedPeriods[i].key === key) continue;
            if (sortedPeriods[i].end && sortedPeriods[i + 1].start && sortedPeriods[i].end.isAfter(sortedPeriods[i + 1].start)) {
                return true;
            }
        }
        return false;
    };

    const checkHourInPrices = useCallback((periods) => {
        // 转换prices中的每个hour为一个数字范围
        const priceHours = prices.filter(i => i.type !== type).map(price => price.hour);

        // 遍历每个时间段
        for (let period of periods) {
            if (period.start !== null && period.end !== null) {
                const startHour = period.start.hour(); // 获取开始时间的小时部分
                const endHour = period.end.hour(); // 获取结束时间的小时部分

                // 检查时间段中的每个小时是否在prices的hour数组中
                for (let hour = startHour; hour < endHour; hour++) {
                    if (priceHours.includes(hour)) {
                        return true; // 如果找到任何匹配，返回true
                    }
                }
            }
        }
        return false; // 如果没有任何匹配，返回false
    }, [prices]);

    const updateTimePeriod = useCallback((value, key) => {
        let newPeriods = [...timePeriods];
        let index = newPeriods.findIndex(period => period.key === key);

        if (index !== -1) {
            if (!value) {
                // 清除掉newPeriods[index]中的start和end在prices中的值
                setPrices(prices.filter(price => {
                    return !newPeriods[index].start || price.hour < newPeriods[index].start.hour() || price.hour > newPeriods[index].end.hour();
                }), elecIndex);

                newPeriods[index] = {
                    ...newPeriods[index],
                    start: null,
                    end: null,
                };
                setTimePeriods(newPeriods);
                return;
            }

            newPeriods[index] = {
                ...newPeriods[index],
                start: value[0],
                end: value[1],
            };

            // Check for overlaps
            if (!checkOverlap(newPeriods, key) && !checkHourInPrices(newPeriods)) {
                // Only update state if there is no overlap
                setTimePeriods(newPeriods);
            } else {
                message.error('时间段不能重叠，请重新选择');
                setTimePeriods(
                    timePeriods.map(period => {
                        if (period.key === key) {
                            return {
                                ...period,
                                key: uuidv4(),
                            }
                        }
                        return period;
                    })
                )
            }
        }
    }, [checkHourInPrices, timePeriods]);

    const disabledHours = useCallback(() => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            hours.push(i);
        }
        const priceHours = prices.map(price => price.hour);
        return hours.filter(hour => priceHours.includes(hour));
    }, [prices, type]);

    // 找到可以选择的第一个小时
    const findFirstAvailableHour = useCallback(() => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            hours.push(i);
        }
        const priceHours = prices.map(price => price.hour);
        return hours.find(hour => !priceHours.includes(hour));
    }, [prices, type]);

    const pricingTier = pricingTierSufix[type];

    return (
        <div className="elec-price-item">
            <div className="elec-price-item__price">
                <span className="label1">{pricingTier}电价：</span>
                <InputNumber value={price}
                             onChange={(value) => setPrice(value)}
                             step={0.01}
                />
                <span className="label2">元/kWh</span>
            </div>


            <div className="time-proid">
                <div className="label-container">
                    {pricingTier}时段：
                </div>
                <div className="picker-container">
                    {
                        timePeriods.map((period, index) => {
                            return <div className="picker-group" key={`${new Date().getTime()}-${index}`}>
                                <TimePicker.RangePicker format={format}
                                                        disabledMinutes={disabledMinutes}
                                                        hourStep={1}
                                                        minuteStep={30}
                                                        placeholder={['开始时段', '结束时段']}
                                                        value={[period.start, period.end]}
                                                        onChange={(value) => updateTimePeriod(value, period.key)}
                                                        onClear={() => updateTimePeriod(null, period.key)}
                                    // disabled={planStore.hasPriceTemplateId()}
                                                        needConfirm={false}
                                                        disabledHours={disabledHours}
                                                        showNow={false}
                                                        defaultOpenValue={[moment(findFirstAvailableHour(), format), moment(findFirstAvailableHour(), format)]}
                                />
                                {
                                    index === 0 ?
                                        <Button className="elec-price-item__add-button" shape="circle"
                                                icon={<PlusCircleFilled style={{color: "#1677FF"}}
                                                                        onClick={addTimePeriod}
                                                />}/> : <Button className="elec-price-item__add-button" shape="circle"
                                                                icon={<MinusCircleFilled style={{color: "#D85656"}}
                                                                                         onClick={() => removeTimePeriod(period.key)}

                                                                />}/>
                                }
                            </div>
                        })
                    }
                </div>
            </div>

        </div>
    );
}

export default observer(ElecPrice);
