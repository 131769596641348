import React from 'react';

const TermsOfService = () => {
    return (
        <div style={{overlay: 'auto', height: '100%'}}>
            <h1>使用条款</h1>
            <p>欢迎使用则鸣新能源提供的储能规划工具！在使用我们的服务前，请仔细阅读以下使用条款。通过访问和使用我们的服务，您同意受这些条款的约束。</p>
            <h2>1. 使用许可</h2>
            <p>则鸣新能源授予您有限的、非排他性的、不可转让的许可，以个人和非商业目的使用本网站提供的服务。</p>
            <p>您同意不会用于任何非法目的或以任何非法方式使用我们的服务，并遵守所有相关的本地、国家及国际法律和规定。</p>
            <h2>2. 服务变更和终止</h2>
            <p>我们保留随时修改或终止服务（或其任何部分）的权利，无需事先通知。</p>
            <p>如果您违反本条款，我们有权随时终止或暂停您的账户和使用我们服务的权限。</p>
            <h2>3. 用户责任</h2>
            <p>您负责保护自己的账户密码，并对自己账户中的所有活动负责。</p>
            <p>您同意不传播任何违法、骚扰、侵犯隐私权、侮辱性、暴力的或其他不当的材料。</p>
            <h2>4. 知识产权</h2>
            <p>本网站及其原创内容、功能和功能性软件是属于则鸣新能源和/或其许可人的财产，受国内外知识产权法的保护。</p>
            <h2>5. 第三方链接</h2>
            <p>本服务可能包含链接到第三方网站或服务，这些第三方链接不受则鸣新能源的控制。</p>
            <p>则鸣新能源不对任何第三方网站的内容、隐私政策或做法负责，也不承担任何责任。</p>
            <h2>6. 免责声明</h2>
            <p>我们的服务是按“现状”提供，不提供任何形式的明示或暗示保证，包括但不限于商业适销性、特定目的的适用性及非侵权。</p>
            <p>则鸣新能源不保证服务的不间断、安全或无误。</p>
            <h2>7. 限制责任</h2>
            <p>则鸣新能源及其供应商和许可方不对任何间断、偶然、特殊、后果性或惩罚性损害负责。</p>
            <h2>8. 适用法律</h2>
            <p>本条款将受中华人民共和国法律管辖，并据此解释。</p>
            <h2>9. 更改</h2>
            <p>我们保留随时更新或更改这些条款的权利。如果我们进行更改，将通过网站上的公告或通过其他通知方式通知您。</p>
            <h2>10. 联系我们</h2>
            <p>如果您对这些条款有任何问题，请通过以下方式联系我们：</p>
            <ul>
                <li>电话： --</li>
                <li>地址： 北京市大兴区北兴路东段2号星光影视园新媒体大厦19号楼西区801室</li>
            </ul>
        </div>
    );
}

export default TermsOfService;
