import React, {useState} from 'react';
import {Form, Input, Radio, Row, Col, InputNumber} from 'antd';
import {observer} from "mobx-react";
import {planStore, UsageTypes} from "../../../store";

const ConsiderWeekends = () => {

    // 更新时间周期选择（年或月）
    const handleTimePeriodChange = e => {
        planStore.toggleEnableLoadEditWeekend(e.target.value);
    };

    return (
        <Form layout="vertical">
            <Form.Item
                // label="考虑周末"
            >
                <Radio.Group
                    defaultValue={planStore.enableLoadEditWeekend}
                    buttonStyle="solid"
                    onChange={handleTimePeriodChange}
                >
                    <Radio.Button value={true}>考虑</Radio.Button>
                    <Radio.Button value={false}>不考虑</Radio.Button>
                </Radio.Group>
                <div style={{marginTop: '10px'}}>
                    {planStore.enableLoadEditWeekend === true ? (

                        <Form.Item label="周末占当前月平时用电量的占比" style={{ marginBottom: 0 }}>
                            <Input.Group compact>
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={5}
                                    value={planStore.loadEditWeekendPercent}
                                    onChange={(value) => planStore.setLoadEditWeekendPercent(value)}
                                    placeholder="周末占比"
                                    style={{ width: 'calc(100% - 55px)' }} // 调整宽度以适应单位标签
                                />
                                <Input
                                    style={{ width: '55px', backgroundColor: '#f0f0f0', pointerEvents: 'none', color: '#000' }}
                                    defaultValue="%"
                                    disabled
                                />
                            </Input.Group>
                        </Form.Item>
                    ) : null}
                </div>
            </Form.Item>
        </Form>
    );
};

export default observer(ConsiderWeekends);
