import './style.scss';

function MenuItem({icon, title, onClick}) {
  return (
    <div className={`menu-item`} onClick={onClick}>
      <div
        className="menu-item__image"
      >
          {icon}
      </div>
      <div className="menu-item__content">
        <h1 className="menu-item__title">{title}</h1>
      </div>
    </div>
  );
}

export default MenuItem;
