import {StepActive, SetpDeactice, StepFirstDeactive} from "../../assets/svg/step-active";
import './style.scss'
import {useEffect, useState} from "react";

function OpStep({
                    currentStep,
                    setCurrentStep,
                }) {

    const [steps, setSteps] = useState([
        {
            title: '第1步',
            active: true,
        }, {
            title: '第2步',
            active: false,
        }, {
            title: '第3步',
            active: false,
        }, {
            title: '第4步',
            active: false,
        }, {
            title: '查看规划结果',
            active: false,
        },
    ]);

    useEffect(() => {
        const newSteps = steps.map((step, index) => {
            if (index === currentStep) {
                return {
                    ...step,
                    active: true,
                }
            } else {
                return {
                    ...step,
                    active: false,
                }
            }
        });
        setSteps(newSteps);
    }, [currentStep]);

    const renderStep = (step, index) => {
        return step.active ? <StepActive /> : index === 0 ? <StepFirstDeactive /> :
            <SetpDeactice />
    }

    return <div className="op-step-container">
        {
            steps.map(
                (step, index) => {
                    return <div
                        key={`step-${index}`}
                        className={`op-step-container__item ${index === 0 ? 'op-step-container__item_first' : ''} ${index === steps.length - 1 ? 'op-step-container__item_last' : ''} ${step.active ? 'active' : ''}`}
                        style={{
                            // cursor: 'pointer',
                            // 文字不可选中
                            userSelect: 'none',
                            position: 'relative',
                            left: `${-60 * index}px`,
                            zIndex: steps.length - index,
                        }}
                    >
                        {index !== steps.length - 1 && renderStep(step, index)}
                        <div className="op-step-container__item-title" style={{
                            color: step.active ? '#fff' : '#525A65'
                        }}>{step.title}</div>
                    </div>
                }
            )
        }


        {/*<div className="op-step-container__item op-step-container__item_first">*/}
        {/*    <StepActive></StepActive>*/}
        {/*</div>*/}
        {/*<div className="op-step-container__item">*/}
        {/*    <SetpDeactice></SetpDeactice>*/}
        {/*</div>*/}
        {/*<div className="op-step-container__item">*/}
        {/*    <SetpDeactice></SetpDeactice>*/}
        {/*</div>*/}
        {/*<div className="op-step-container__item">*/}
        {/*    <SetpDeactice></SetpDeactice>*/}
        {/*</div>*/}
        {/*<div className="op-step-container__item op-step-container__item_last"></div>*/}
    </div>;
}

export default OpStep;
