import './chart-top-card.scss';

// 处理数字，增加千位分隔符，仅处理整数部分，小数部分保留2位
function formatNumber(num) {
    if (isNaN(num)) return num;
    if (num < 0) num = Math.abs(num);
    const [integer, decimal] = num.toString().split('.');
    const integerStr = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimal ? `${integerStr}.${decimal.slice(0, 2)}` : integerStr;
}

function ChartTopCard({
                          title, value, unit = ''
                      }) {
    return (<div className="chart-top-card">
            <div className="chart-top-card__title">{title}</div>
            <div className="chart-top-card__value">{formatNumber(value)}
                {unit && <span>{unit}</span>}
            </div>
        <div className="bottom-line" />
        </div>);
}

export default ChartTopCard;
