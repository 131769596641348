import './base-info.scss'
import TitleBoard from "../../components/title-board";
import {Tabs, Form, Input, Button, Select, Cascader, Spin, message} from "antd";
import {useEffect, useState} from "react";
import PcaCode from '../../assets/pca-code.json';
import PrivacyPolicy from "../../components/PrivacyPolicy";
import TermsOfService from "../../components/TermsOfService";
import {observer} from "mobx-react";
import {planStore} from "../../store";
import {updateProfile} from "../../services/api";
import {useNavigate} from "react-router-dom";

const {TextArea} = Input;
const {Option} = Select;

function BasicInfo() {

    const navigate = useNavigate();


    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        values.province = values.location[0];
        values.city = values.location[1];
        updateProfile(values).then(res => {
            console.log('updateProfile', res);
            if (res.data.code === 0) {
                message.success('更新成功');
                planStore.setProfile(values);
            } else {
                message.error('更新失败');
            }
        }).catch(err => {
            console.error(err);
            message.error('更新失败');
        })
    };

    return <div className="base-info-container">
        {/*<TitleBoard subTitle={'个人信息'} title={'基本信息编辑'}></TitleBoard>*/}
        <div className="base-info__main">
            <div className="base-info__content">
                <Tabs tabPosition="left" defaultActiveKey="1">
                    <Tabs.TabPane tab="基本信息" key="1">
                        {!planStore?.profile && <Spin/>}
                        {!!planStore?.profile && <Form
                            name="complex_form"
                            onFinish={onFinish}
                            labelCol={{span: 6}}
                            wrapperCol={{span: 16}}
                            initialValues={planStore.profile}
                            style={{width: '460px'}}
                            layout={'vertical'}
                        >
                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{required: true, message: '请输入姓名!'}]}
                            >
                                <Input placeholder={"请填写名称"}/>
                            </Form.Item>
                            <Form.Item
                                label="单位"
                                name="company"
                                rules={[{required: true, message: '请输入单位名称!'}]}
                            >
                                <Input placeholder={"请填写单位名称"}/>
                            </Form.Item>
                            <Form.Item
                                label="单位简介"
                                name="companyDesc"
                            >
                                <TextArea rows={4} placeholder="请输入单位简介"/>
                            </Form.Item>
                            <Form.Item
                                label="省市"
                                name="location"
                                rules={[{required: true, message: '请选择省市县!'}]}
                            >
                                <Cascader options={PcaCode}/>
                            </Form.Item>
                            <Form.Item
                                label="详细地址"
                                name="address"
                                rules={[{required: true, message: '请输入详细地址!'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item wrapperCol={
                                {
                                    span: 8,
                                    offset: 12,
                                }
                            }>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Form.Item>
                        </Form>}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="安全设置" key="2">
                        <Button type="primary" style={{width: 200}} onClick={
                            () => {
                                // 清理掉satoken的cookie
                                document.cookie = 'satoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                                navigate('/')
                            }
                        }>注销登录</Button>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="隐私协议" key="3">
                        <PrivacyPolicy/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="使用条款" key="">
                        <TermsOfService/>
                    </Tabs.TabPane>
                </Tabs>
            </div>

        </div>
    </div>;
}

export default observer(BasicInfo);
