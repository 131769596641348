import React from "react";
import TxtLoading from "../TxtLoading";
import {observer} from "mobx-react";
import {planStore} from "../../store";

const LoadingDemo = ({
                         onFinish = () => {
                         },
                     }) => {
    return (
        <TxtLoading
            toRotate={[
                "站点数据上传",
                "推荐容量分析",
                "收益数据分析",
            ]}
            periods={[2000, 5000, 20000]}
            onFinish={() => {
                onFinish()
            }}
            completed={planStore.submitCompleted}
        />
    );
};

export default observer(LoadingDemo);
