import React, {useEffect, useMemo} from 'react';
import ReactECharts from 'echarts-for-react';
import * as moment from 'moment';

function LoadCurveChart({
                            loadCurveData,
                            pcsPowers,
                            socs,
                            showTitle = true,
                        }) {

    console.log('loadCurveData', loadCurveData)

    const data = useMemo(() => {
        const legend = ['负荷曲线'];
        const series = [
            {
                name: '负荷曲线',
                type: 'line',
                data: loadCurveData.map(item => item.loads),
                smooth: true
            },
        ];

        if (pcsPowers?.length > 0) {
            legend.push('充放功率曲线');
            series.push({
                name: '充放功率曲线',
                type: 'line',
                data: pcsPowers,
                smooth: true,
            });
        }

        if (socs?.length > 0) {
            legend.push('SOC电池充放状态曲线');
            series.push({
                name: 'SOC电池充放状态曲线',
                type: 'line',
                data: socs.map(item => item * 100),
                smooth: true,
                yAxisIndex: 1,
            });
        }

        return {
            legend,
            series,
        }
    }, [
        loadCurveData,
        pcsPowers,
        socs,
    ]);

    const getOption = () => {
        return {
            color: ['rgba(25, 144, 255, 1)', "rgba(255, 210, 143, 1.000)", "rgba(156, 186, 183, 1.000)"],
            title: {
                text: showTitle ? '历史负荷和模拟运行数据' : '',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                textStyle: {
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: 14
                },
                formatter: function (params) {
                    let result = params[0].name + '<br/>';
                    params.forEach(function (item) {
                        if (item.seriesName === 'SOC电池充放状态曲线') {
                            // 如果数据系列名称为 "SOC电池充放状态曲线"，则使用 "%"
                            result += item.seriesName + ': ' + item.value.toFixed(2) + ' %<br/>';
                        } else {
                            // 其他情况，使用 "kW"
                            result += item.seriesName + ': ' + item.value.toFixed(2) + ' kW<br/>';
                        }
                    });
                    return result;
                }
            },
            // legend: {
            //     data: ['负荷曲线',
            //         // '充放功率曲线',
            //         // 'SOC电池充放状态曲线',
            //     ]
            // },
            legend: {
                data: data.legend,
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '15%', // 为 dataZoom 留出空间
                containLabel: true
            },
            xAxis: {
                type: 'category',
                // data: loadCurveData.map(item => item.time),
                // 使用标准时间
                data: loadCurveData.map(item => moment(new Date(item.time)).format('MM/DD HH:mm')),
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {
                        color: '#333'
                    }
                },
            },
            yAxis: [{
                type: 'value',
                name: 'KW',
                axisLabel: {
                    color: '#999',
                    fontFamily: 'Roboto, sans-serif'
                },
                axisLine: {
                    lineStyle: {
                        color: '#333'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: ['#eee'],
                        type: 'dotted'
                    }
                }
            },
                socs?.length > 0 && {
                    type: 'value',
                    name: '%',
                    position: 'right',
                    axisLabel: {
                        color: '#999',
                        fontFamily: 'Roboto, sans-serif'
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#333'
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#eee'],
                            type: 'dotted'
                        }
                    }
                },
            ].filter(Boolean),
            dataZoom: [
                {
                    type: 'slider',
                    start: 0,
                    end: 10,
                    handleStyle: {
                        color: '#35C',
                        shadowBlur: 8,
                        shadowColor: 'rgba(0, 0, 0, 0.3)'
                    }
                },
            ],
            series: data.series,
        };
    };

    return <ReactECharts option={getOption()} style={{height: 400, width: '100%'}}/>;
}

export default LoadCurveChart;
