import DividerTitle from "../../divider-title";
import {Cascader, Form, Input, Select, Space, Tooltip} from "antd";
import PcaCode from '../../../assets/pca-code.json';
import ElecPrice from "./elec-price";
import ElectrovalenceChart from "./electrovalence-chart";
import {useEffect, useMemo, useState} from "react";
import {observer} from 'mobx-react';
import {planStore} from "../../../store";
import {getPriceByCity} from "../../../services/api";
import {InfoCircleOutlined} from "@ant-design/icons";
import ElecPriceBlock from "./elec-price-block";
import {toJS} from "mobx";

function FirstStep({form}) {

    const handleCascaderChange = (value) => {
        if (!value || value?.length === 0) {
            planStore.setPriceTemplateIds([]);
            return;
        }
        // 取value最后一个值
        const province = value[0];
        const city = value[value.length - 1];
        planStore.setSelectedCity(value);
        getPriceByCity(province, city).then(
            res => {
                console.log('getPriceByCity', res.data);
                const data = res.data;
                if (data?.data?.length > 0) {
                    const priceTemplateIds = data.data.map(item => item.data.id);
                    console.log('priceTemplateIds', priceTemplateIds);
                    planStore.setPriceTemplateIds(priceTemplateIds);
                    planStore.setInitStructuredPrices(data.data, {
                        syncStructuredPrices: true,
                        replace: true,
                    });
                    // 设置电价数据
                    try {
                        data?.data?.forEach((item, index) => {
                            // planStore.setStructuredPrices(item, index);
                            item?.data?.months?.split(',').forEach(month => {
                                const mouthNum = parseInt(month) - 1;
                                planStore.setElecPriceMonths(index, mouthNum);
                            })
                            const curPrices = JSON.parse(item?.data?.data || {});
                            let c = curPrices;
                            // curPrices 中基于hour从小到大排序
                            curPrices.sort((a, b) => a.hour - b.hour);
                            if (curPrices[0].hour === 1) {
                                c = curPrices.map(
                                    (price) => ({
                                        ...price,
                                        hour: price.hour - 1,
                                    })
                                )
                            } else {
                                c = curPrices.map(
                                    (price) => ({
                                        ...price,
                                        hour: price.hour,
                                    })
                                )
                            }
                            planStore.setPrices(c, index);
                        });
                    } catch (e) {
                        console.log('电价数据解析失败', e);
                    }
                }
            }).catch(
            err => console.error(err)
        )
    }

    useEffect(() => {
        form.setFieldsValue({
            name: planStore.name,
            location: planStore.selectedCity,
        });
    }, [planStore.name]);

    const structuredPrices = useMemo(() => {
        // planStore.structuredPrices.length > 0 ? planStore.structuredPrices : planStore.initStructuredPrices.length > 0 ? planStore.initStructuredPrices : [{data: {data: {}}}]
        if (planStore.structuredPrices.length > 0) {
            return planStore.structuredPrices;
        }
        return [{}];
    }, [JSON.stringify(planStore.structuredPrices)]);


    return (
        <div className='first-step main-content-width'>
            <Form form={form} layout="horizontal" name="basic"
                  labelCol={{
                      span: 8,
                  }}
                  wrapperCol={{
                      span: 8,
                  }}
                  initialValues={{
                      name: planStore.name,
                      location: planStore.selectedCity,
                  }}
            >
                <DividerTitle title={'基本信息'}></DividerTitle>
                <br/>
                <Space
                    direction="vertical"
                    size={0}
                    style={{
                        display: 'flex',
                    }}
                >
                    <Form.Item
                        name="location"
                        label="地区"
                    >
                        <Cascader options={PcaCode} placeholder="请选择省市区" onChange={handleCascaderChange}/>
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="项目名称&描述"
                    >
                        <Input placeholder="请输入项目名称&描述（最多100个字符）"/>
                    </Form.Item>
                </Space>
            </Form>
            {
                structuredPrices.map((item, index) => {
                    return <>
                        <ElecPriceBlock key={index} index={index}/>
                        <br/>
                    </>
                })
            }
        </div>
    );
}

export default observer(FirstStep);
