import {Modal, Input, Button, Form, message, Col, Row} from 'antd';
import {MobileOutlined, LockOutlined} from '@ant-design/icons';
import {useCallback, useEffect, useState} from "react";

import './style.scss';
import {planStore} from "../../store";
import LoadingDemo from "../TxtLoading/LoadingDemo";


function LoginModal({isVisible, onClose}) {

    return (
        <Modal
            title=""
            visible={isVisible}
            // onCancel={onClose}
            footer={null}
            className="loading-modal"
            width={372}
            maskClosable={false}
            centered
            closable={false}
        >
            <div className="loading-modal_content">
                <LoadingDemo key={new Date().getTime()}/>
            </div>
        </Modal>
    );
}

export default LoginModal;
