import './style.scss';
import {Divider} from "antd";
function FootBoot() {
  return (
    <footer className="footer-content">
      <span>储能规划设计 |  Copyright 𝕔 Zenergy Tech Co., Ltd. All Rights Reserved </span>
        <div>
            {/*<span>隐私协议</span>*/}
            {/*<Divider type='vertical' style={{borderColor: '#525A65'}}></Divider>*/}
            {/*/!*使用条款 *!/*/}
            {/*<span>使用条款</span>*/}
            {/*<Divider type='vertical' style={{borderColor: '#525A65'}}></Divider>*/}
            {/*/!*联系我们*!/*/}
            {/*<span>联系我们</span>*/}
            {/*<Divider type='vertical' style={{borderColor: '#525A65'}}></Divider>*/}
            {/*/!*帮助*!/*/}
            {/*<span>帮助</span>*/}
        </div>
    </footer>
  );
}
export default FootBoot;
