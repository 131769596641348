import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons";

import './style.scss'
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {planStore} from "../../../store";
import {getProfile} from "../../../services/api";

// 手机号脱敏
function maskPhone(phone) {
    return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

function Account() {

    useEffect(() => {
        getProfile().then(res => {
            console.log('getProfile', res);
            if (res.data.code === 0) {
                planStore.setIsLogin(true);
                planStore.setProfile(res.data.data);
            }
        }).catch(e => {
            console.log('getProfile error', e);
        })
    }, []);

    return (
        <div className='account-group'>
            <Avatar icon={<UserOutlined/>} size="small"/>
            {!planStore.isLogin &&
                <a className='account-group__login'
                   onClick={() => planStore.setLoginModalVisible(true)
                   }>登录</a>}
            {planStore.isLogin && <span className='account-group__name'>{
                planStore.profile?.account ? maskPhone(planStore.profile.account) : '未登录'
            }</span>}
        </div>
    );
}

export default observer(Account);
