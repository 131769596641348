import React, {useEffect, useState} from 'react';
import ExcelIcon from '../../../assets/images/excel.png';
import {message, Spin, Upload, UploadProps, Button, Tooltip, Row, Col, Divider, Modal} from 'antd';
import './style.scss'
import {getLoadCurveById, importLoadCurveByData} from "../../../services/api";
import LoadCurveChart from "./load-curve-chart";
import {observer} from "mobx-react";
import {planStore, TemplateTypes} from "../../../store";
import ChartTopCard from "./chart-top-card";
import classnames from "classnames";
import {CloudUploadOutlined, InfoCircleOutlined} from "@ant-design/icons";
import DailyLoadChart from "./DailyLoad-chart";
import BuildTypeSelect from "./BuildTypeSelect";
import HourlyLoadInput from "./ManuallyDailyTemplate";
import EnergyConsumptionInput from "./EnergyConsumptionInput";
import ConsiderWeekends from "./ConsiderWeekends";
import {generateYearlyLoadData} from "../../../utils/generateYearlyLoadData";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const {Dragger} = Upload;

// 计算平均负载
const calcAvgLoad = (loadData) => {
    if (loadData.length < 1) return 0;
    const totalLoad = loadData.reduce((prev, cur) => prev + cur.loads, 0);
    return totalLoad / loadData.length;
}

// 计算最大负载
const calcMaxLoad = (loadData) => {
    if (loadData.length < 1) return 0;
    return Math.max(...loadData.map(item => item.loads));
}

// 计算最小负载
const calcMinLoad = (loadData) => {
    if (loadData.length < 1) return 0;
    return Math.min(...loadData.map(item => item.loads));
}

const UploadLoad = () => {

    /**
     * 未上传，上传中，上传完成的英文
     * @type {string} 'not-uploaded' | 'uploading' | 'uploaded'
     */
    const [uploadStatus, setUploadStatus] = useState('not-uploaded');
    // const [uploadStatus, setUploadStatus] = useState('uploaded');

    // 负荷模版id
    // const [loadCurveId, setLoadCurveId] = useState(1567322124);

    /**
     * 负荷数据， time: '2022-01-01 02:15:00', capacity: 630, loads: 8.16, maxDemand: 8.16
     * @type {Array} [{time: string, capacity: number, loads: number, maxDemand: number}]
     */
    // const [loadData, setLoadData] = useState([])

    useEffect(() => {
        if (!planStore.hasLoadCurveId()) return;
        getLoadCurveById(planStore.loadCurveId).then((res) => {
            console.log('getLoadCurveById', res);
            try {
                const data = res.data.data.data;
                const loadCurve = JSON.parse(data);
                planStore.setLoadData(loadCurve);
            } catch (e) {
                console.error(e);
                setUploadStatus('not-uploaded');
            }
        }).catch(
            error => {
                console.error(error);
            }
        )
    }, [planStore.loadCurveId]);

    /**
     * @type UploadProps
     */
    const props = {
        name: 'file',
        action: '/api/import/loadCurve',
        data: {
            "province": planStore.selectedCity[0],
            "city": planStore.selectedCity[0],
        },
        maxCount: 1,
        accept: '.xlsx',
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
                const response = info.file?.response;
                const data = response?.data;
                if (data) planStore.setLoadCurveId(data);
            }
            if (status === 'done') {
                setUploadStatus('uploaded');
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                setUploadStatus('not-uploaded');
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
            setUploadStatus('uploading');
        },
        showUploadList: false,
    };

    const renderEditorTypeByFile = () => (
        <div className={classnames(["second-step__upload", {
            "second-step__upload--upload_dragger": planStore.loadCurveId && planStore.loadData?.length > 0,
        }])}>
            {(planStore.loadCurveId && planStore.loadData?.length < 1) && <Spin/>}
            {
                !planStore.loadCurveId && <Dragger {...props}>
                    {
                        uploadStatus === 'not-uploaded' && (
                            <>
                                <p className="ant-upload-drag-icon">
                                    <img src={ExcelIcon} alt="" style={{width: 32}}/>
                                </p>
                                <p className="ant-upload-text">将文件拖到此处或点击上传历史负荷文件</p>
                                <p className="ant-upload-hint">
                                    下载示例文件<Tooltip title={
                                    <p>
                                        <p> 1.请从电网相关部门获取该历史数据，或自行准备历史数据</p>
                                        <p> 2.数据应包含全年365天的用电信息，采样频率为每15分钟1个点</p>
                                        <p> 3.示例文件中的变压器容量和最大需量数据不是必须的</p>
                                    </p>
                                }>
                                    <InfoCircleOutlined style={{margin: '0px 4px', color: '#000', width: 12, height: 12}}/>
                                </Tooltip>：<a onClick={(event) => {
                                    event.preventDefault();
                                    window.open('https://zenergytech-esp.oss-cn-beijing.aliyuncs.com/load-temp/%E7%A4%BA%E4%BE%8B%E6%96%87%E4%BB%B6.xlsx');
                                }}>示例文件</a>
                                </p>
                                <Button onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    planStore.setLoadCurveId('2');
                                }}>使用示例文件</Button>
                            </>
                        )
                    }

                    {
                        uploadStatus === 'uploading' &&
                        <div className="second-step__upload-loading">
                            <Spin/>
                            <div>上传中...</div>
                        </div>
                    }
                </Dragger>
            }
        </div>
    )

    const [isModalVisible, setIsModalVisible] = useState(false);

    const renderEditorTypeBySimulation = () => (
        <div className={classnames(["second-step__upload", {
            "second-step__upload--upload_dragger": planStore.loadCurveId && planStore.loadData?.length > 0,
        }, "second-step__simulation"])}>
            {(planStore.loadCurveId && planStore.loadData?.length < 1) && <Spin/>}
            {
                !planStore.loadCurveId && <div className="second-step__upload-simulation">
                    <Divider orientation="left" plain>
                        日负荷模板
                    </Divider>
                    <BuildTypeSelect/>
                    {planStore.loadEditType === TemplateTypes.CUSTOM && <HourlyLoadInput/>}
                    <DailyLoadChart/>
                    <br/>
                    {!planStore.isLoadEditTypeCustom() && <>
                        <Divider orientation="left" plain>
                            能耗录入（KWH）
                        </Divider>
                        <EnergyConsumptionInput/>
                    </>}

                    <Divider orientation="left" plain>
                        考虑周末
                    </Divider>
                    <ConsiderWeekends/>
                    <Row style={{margin: '20px 0'}}>
                        <Col span={24} style={{textAlign: 'right'}}>
                            <Button type="primary"
                                    shape="round"
                                    icon={<CloudUploadOutlined/>}
                                    onClick={async() => {
                                        if (!planStore.isLoadEditValid()) {
                                            console.log('数据不合法');
                                            return;
                                        }
                                        setIsModalVisible(true);
                                        const loads = generateYearlyLoadData({
                                            loadEditDay24h: planStore.loadEditDay24h,
                                            loadEditType: planStore.loadEditType,
                                            loadEditMonthYear: planStore.loadEditMonthYear,
                                            enableLoadEditWeekend: planStore.enableLoadEditWeekend,
                                            loadEditWeekendPercent: planStore.loadEditWeekendPercent,
                                            loadEditYearPowerConsumption: planStore.loadEditYearPowerConsumption,
                                            loadEditMonthsPowerConsumption: planStore.loadEditMonthsPowerConsumption,
                                        })

                                        await importLoadCurveByData({
                                            data: JSON.stringify(loads),
                                            province: planStore.selectedCity[0],
                                            city: planStore.selectedCity[1],
                                        }).then((res) => {
                                            console.log('importLoadCurveByData', res);
                                            try {
                                                const data = res.data.data.data;
                                                const loadCurve = JSON.parse(data);
                                                planStore.setLoadData(loadCurve);
                                                planStore.setLoadCurveId(res.data.data.id);
                                            } catch (e) {
                                                console.error(e);
                                                setUploadStatus('not-uploaded');
                                            }
                                        }
                                        ).catch(
                                            error => {
                                                console.error(error);
                                            }
                                        )
                                        setIsModalVisible(false);
                                    }}
                            >
                                生成全年负荷数据
                            </Button>
                        </Col>
                    </Row>
                    <Modal
                        open={isModalVisible}
                        maskClosable={false}
                        closable={false}
                        footer={[]}
                    >
                        <message >   全年负荷数据生成中 ...</message>
                    </Modal>
                </div>
            }
        </div>
    )

    return (
        <>

            {planStore.enableLoadEdit && renderEditorTypeByFile()}
            {!planStore.enableLoadEdit && renderEditorTypeBySimulation()}

            {
                (planStore.loadCurveId && planStore.loadData?.length > 0) &&
                <div className="second-step__uploaded">
                    <div className="chart-top-card-group">
                        <ChartTopCard title={'平均负荷'} value={calcAvgLoad(planStore.loadData)} unit="KW"/>
                        <ChartTopCard title={'最小负荷'} value={calcMinLoad(planStore.loadData)} unit="KW"/>
                        <ChartTopCard title={'最大负荷'} value={calcMaxLoad(planStore.loadData)} unit="KW"/>
                    </div>
                    <LoadCurveChart loadCurveData={planStore.loadData}/>
                </div>
            }
        </>

    )
};
export default observer(UploadLoad);
