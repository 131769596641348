import React from "react";
import "./index.scss";
import CountUp from "react-countup";

export default class TxtLoading extends React.Component{
    loopNum = 0;
    txt = "";

    state = {
        innerHTML: "",
        data: [],
    };

    componentDidMount() {
        this.tick();
    }

    tick = () => {
        const toRotate = this.props.toRotate;
        const i = this.loopNum % toRotate.length;
        const fullTxt = toRotate[i];

        this.txt = fullTxt.substring(0, this.txt.length + 1);

        this.setState({
            innerHTML: this.txt,
        });

        let delta = 10;

        if (this.txt === fullTxt) {
            this.setState({
                data: this.state.data.concat(fullTxt),
                innerHTML: "",
            });
            this.txt = "";
            delta = this.props.periods[this.loopNum] + 100;
            this.loopNum++;
        }
        setTimeout(() => {
            if (this.loopNum < toRotate.length) {
                this.tick();
            } else {
                this.props.onFinish();
            }
        }, delta);
    };

    render() {
        return (
            <div className="loading">
                {this.state.data.map((value, index) => (
                    <div className="loading-item" key={index}>
                        <div className="text">{value}</div>
                        <CountUp
                            start={0}
                            end={100}
                            duration={this.props.periods[index] / 1000}
                            className={"count"}
                            suffix={"%"}
                        />
                    </div>
                ))}
                {this.state.innerHTML && (
                    <span className="wrap">{this.state.innerHTML}</span>
                )}
            </div>
        );
    }
}
