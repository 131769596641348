import {Outlet} from 'react-router-dom';
import LeftSide from "../components/left-side";
import TopHead from "../components/top-head";
import FootBoot from "../components/foot-boot";
import './main-layout.scss';

function MainLayout() {
    return (
        <div className="main-layout">
            <div className="main-layout__left-nav">
                <LeftSide/>
            </div>
            <div className="main-layout__right-content">
                <div className="main-layout__right-content__top-head">
                    <TopHead/>
                </div>
                <div className="main-layout__right-content__outlet">
                    <Outlet/>
                </div>
                <div className="main-layout__right-content__foot-boot">
                    <FootBoot/>
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
