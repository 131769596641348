import React, { useState, useEffect } from 'react';

import './style.scss';

const timeZoneAbbreviations = {
    'Asia/Shanghai': 'BJT', // 北京时间
    'America/New_York': 'EST', // 东部标准时间
    'Europe/London': 'GMT', // 格林威治标准时间
    'Europe/Paris': 'CET', // 中欧时间
    'America/Los_Angeles': 'PST', // 太平洋标准时间
    'Asia/Tokyo': 'JST', // 日本标准时间
    // 添加更多时区映射
};

function getCustomTimeZoneAbbreviation(timeZone) {
    return timeZoneAbbreviations[timeZone] || timeZone; // 如果没有找到映射，返回原始时区标识
}

const TimeDisplay = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
            setCurrentTime(new Date()); // 每秒更新时间
        }, 1000);

        return () => clearInterval(timerId); // 组件卸载时清除计时器
    }, []);

    const timeZoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).resolvedOptions().timeZone;
    const formattedTime = currentTime.toLocaleTimeString('en-US', { timeZone: timeZoneName, hour12: false }).substring(0, 5);

    return (
        <div className="time-display">
            <p>{formattedTime}</p>
            <p>{getCustomTimeZoneAbbreviation(timeZoneName)}</p>
        </div>
    );
};

export default TimeDisplay;
