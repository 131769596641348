import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default async function generateFingerprintUUID() {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId; // 这是一个根据浏览器信息生成的唯一ID

    // 将得到的指纹哈希转换为UUID格式（如果需要）
    return fingerprintToUUID(fingerprint);
}

function fingerprintToUUID(fingerprint) {
    // 确保指纹字符串长度至少为32字符（简单示例，可以自定义）
    let hash = fingerprint.padEnd(32, '0');
    // 创建类似于UUID格式的字符串
    return `${hash.slice(0, 8)}-${hash.slice(8, 12)}-${hash.slice(12, 16)}-${hash.slice(16, 20)}-${hash.slice(20, 32)}`;
}
