import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '/api' : '/api';

// 创建axios实例
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 100000,
});

// 请求拦截器
// api.interceptors.request.use(config => {
//      const token = sessionStorage.getItem('authToken');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, error => {
//     return Promise.reject(error);
// });

// 响应拦截器
// api.interceptors.response.use(response => {
//     // 对响应数据做点什么
//     return response.data;
// }, error => {
//     if (error.response.status === 401) {
//     }
//     return Promise.reject(error);
// });

// 导出通用的HTTP方法
export const get = (url, params) => api.get(url, {params});
export const post = (url, data) => api.post(url, data);
export const put = (url, data) => api.put(url, data);
export const del = (url, data) => api.delete(url, data);
