export function StepActive(props) {
  return (

      <svg width="100%" height="100%" viewBox="0 0 237 48" >
          <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="1" fill="#2098F1" stroke="#2098F1">
                  <path d="M226.105369,0.5 L236.453677,24.2881849 L226.108552,47.5 L3,47.5 C2.30964406,47.5 1.68464406,47.220178 1.23223305,46.767767 C0.779822031,46.3153559 0.5,45.6903559 0.5,45 L0.5,3 C0.5,2.30964406 0.779822031,1.68464406 1.23223305,1.23223305 C1.68464406,0.779822031 2.30964406,0.5 3,0.5 L226.105369,0.5 Z" id="BG"></path>
              </g>
          </g>
      </svg>
  );
}

export function SetpDeactice(props) {
  return (

      <svg width="100%" height="100%" viewBox="0 0 237 48">
          <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Molecules/Wizard/Wizard-5-steps" transform="translate(-225, 0)" fill="#FFFFFF" stroke="#E5E5E5">
                  <g id="2" transform="translate(225, 0)">
                      <path d="M226.105369,0.5 L236.453677,24.2881849 L226.108552,47.5 L0.773831217,47.5 L11.1132168,24.5624973 L0.759464953,0.5 L226.105369,0.5 Z" id="BG"></path>
                  </g>
              </g>
          </g>
      </svg>
  );
}

export function StepFirstDeactive(props) {
  return (<svg  width="100%" height="100%" viewBox="0 0 237 48">
          <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Molecules/Wizard/Wizard-5-steps" transform="translate(-127, 0)" fill="#FFFFFF" stroke="#E5E5E5">
                  <path d="M353.237538,0.500167906 L363.454794,24.2882109 L353.240632,47.5 L130.07984,47.5 C129.340669,47.2494111 128.69129,46.7759657 128.228287,46.1586281 C127.774336,45.5533606 127.5,44.8088323 127.5,44 L127.5,4 C127.5,3.18577803 127.777947,2.43644171 128.244327,1.84187992 C128.709086,1.24938539 129.360667,0.810243166 130.110594,0.613879674 L353.237538,0.500167906 Z" id="形状结合"></path>
              </g>
          </g>
      </svg>
  );
}
