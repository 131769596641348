import React, {useState} from 'react';
import {Radio, Select} from 'antd';
import './style.scss';
import {TemplateTypes, UsageTypes} from "../../../store";
import {observer} from "mobx-react";
import {planStore} from "../../../store";

const {Option} = Select;

const BuildTypeSelect = () => {

    const {loadEditType} = planStore;

    // 处理选择变化
    const handleChange = (e) => {
        const value = e.target ? e.target.value : e;
        planStore.setLoadEditType(value);
        if (value === TemplateTypes.OFFICE) {
            planStore.setLoadEditDay24h([
                50,
                50,
                50,
                50,
                50,
                50,
                50,
                200,
                300,
                400,
                400,
                400,
                300,
                300,
                400,
                400,
                400,
                400,
                300,
                200,
                100,
                50,
                50,
                50,
            ]);
        }

        if (value === TemplateTypes.FACTORY) {
            planStore.setLoadEditDay24h([
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
                650,
            ])
        }

        if (value === TemplateTypes.MALL) {
            planStore.setLoadEditDay24h([
                500,
                500,
                500,
                500,
                500,
                500,
                500,
                800,
                1000,
                1200,
                1600,
                1600,
                2200,
                2200,
                1600,
                1600,
                1600,
                1800,
                2200,
                2200,
                1800,
                1600,
                1000,
                500,
            ])
        }

        if (value === TemplateTypes.CUSTOM) {
            planStore.setLoadEditDay24h(new Array(24).fill(0));
        }
    };

    return (
        <div className={"second-step__build_type"}>
            {/*<label htmlFor="buildTypeSelect" className={"second-step__build_type_label"}>日负荷模板: </label>*/}
            {/*<Select*/}
            {/*    id="buildTypeSelect"*/}
            {/*    value={loadEditType}*/}
            {/*    style={{width: 130}}*/}
            {/*    onChange={handleChange}*/}
            {/*    placeholder="请选择模板"*/}
            {/*>*/}
            {/*    <Option value={TemplateTypes.OFFICE}>办公楼</Option>*/}
            {/*    <Option value={TemplateTypes.FACTORY}>工厂</Option>*/}
            {/*    <Option value={TemplateTypes.MALL}>商场</Option>*/}
            {/*    <Option value={TemplateTypes.CUSTOM}>自定义</Option>*/}
            {/*</Select>*/}
            <Radio.Group
                value={loadEditType}
                buttonStyle="solid"
                onChange={handleChange}
            >
                <Radio.Button value={TemplateTypes.OFFICE}>办公楼</Radio.Button>
                <Radio.Button value={TemplateTypes.FACTORY}>工厂</Radio.Button>
                <Radio.Button value={TemplateTypes.MALL}>商场</Radio.Button>
                <Radio.Button value={TemplateTypes.CUSTOM}>自定义</Radio.Button>
            </Radio.Group>
        </div>
    );
};

export default observer(BuildTypeSelect);
