import './style.scss'
import {RedoOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import {cloneElement} from "react";

function DividerTitle({
                          title,
                          // 刷新回调函数
                          onRefresh,
                          // 刷新按钮是否显示
                          refreshVisible = false,
                          children,
                          midContent,
                          rightContent,
                      }) {
    return (
        <div className="divider-title">
            <div className="divider-title__maincontent">
                <div className="divider-title__title">
                    <span>{title}</span>
                    {refreshVisible && <Tooltip placement="top" title={'清空负荷配置'}>
                   <span className="divider-title__refresh">
                <RedoOutlined onClick={onRefresh}/>
          </span>
                    </Tooltip>}
                    <span style={{width: 10, display: 'inline-block'}}/>
                    {

                        children && cloneElement(children)
                    }
                    <span style={{width: 4, display: 'inline-block'}}/>
                    <span>
                        {
                            midContent && cloneElement(midContent)
                        }
                    </span>
                </div>
                {rightContent && <div className="divider-title__right-content">
                    {cloneElement(rightContent)}
                </div>}
            </div>

            <div className="divider-title__divider"></div>
        </div>
    );
}

export default DividerTitle;
